import React, { useState, useEffect } from "react";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
// import ScreeningDashboard from "./ScreeningDashboard";
import { useLocation, useNavigate } from "react-router-dom";
// import { Screening1Report } from "../Api/ScreeningApi";
import ScreeningDashboard from "../ScreeningDashboard";
import {
  Screening1Reports,
  updateStudentScreeningStatus,
} from "../Api/ScreeningApi";
import Loader from "../../../../utils/loader/Loader";
import backIcon from "../../../../assets/images/back-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Screening1Report = (props) => {
  const { studentReport, setStudForm } = props;
  const [screen1Report, setScreen1Report] = useState();
  const [showDashboard, setShowDashboard] = useState(false);
  const location = useLocation();
  const [loader, setLoader] = useState(true);

  //   console.log(studentReport, "studentReport");
  const getTranslateText = useSelector(getTranslatedTextSelector);

  const studentNames = location.state.studentName;
  const studentId =
    location.state.studentReport.studentID ||
    location.state.studentReport.studentId;

  const navigate = useNavigate();

  const screening1ReportData = () => {
    Screening1Reports(1, 1, studentId).then((response) => {
      console.log(response);
      setScreen1Report(response);
      setLoader(false);
    });
  };

  const getStudentName = (consult) => {
    const addStudentName = consult?.replace("<StudentName>", studentNames);

    return addStudentName;
  };

  useEffect(() => {
    screening1ReportData();
  }, []);

  const updateStudentStatus = async () => {
    updateStudentScreeningStatus(
      location.state.studentReport.studentID ||
        location.state.studentReport.studentId,
      2
    )
      .then((res) => {
        navigate("/user/instruction", {
          state: location.state.studentReport.studentID,
        });
      })
      .catch((error) => {
        console.log(error, "errroor");
      });
  };

  return (
    <>
      {loader !== true ? (
        <>
          <div className="bg-[#F4F4FA] py-[30px] lg:px-[80px] h-[calc(100%_-_9%)]">
            <div className="bg-[#fff] rounded-[25px] h-[100%] ">
              <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#DFE5FF] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex flex-row items-center lg:px-[50px] px-[40px] pt-[10px]">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ color: "#000000" }}
                  size="xl"
                  onClick={() => navigate(-1)}
                  className="mr-5 cursor-pointer"
                />
                <h2 className="text-[#3C3F4F] font-semibold text-left text-[24px] py-[20px]  ">
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "label_scrn_report"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_scrn_report"
                        )[0]?.keyValue
                      : "Screening Report"
                    : "Screening Report"}
                </h2>
              </div>
              <div className="border-t-2 overflow-y-scroll h-[calc(100%_-_12vh)]  ">
                {screen1Report?.map((item, index) => (
                  <div className="lg:mx-[80px] mx-[40px] py-[20px]">
                    <h3 className="text-[#2DA3EF] text-[18px] font-semibold text-left mb-2">
                      {item?.domainDescription}
                    </h3>
                    <div>
                      {item.consultation !== "NA" ? (
                        <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                          <h4 className="text-[#122064cc] text-[18px] font-medium">
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_consult"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_consult"
                                  )[0]?.keyValue
                                : "Consult"
                              : "Consult"}
                          </h4>
                          <p className="text-[#122064ab] text-[16px] font-regular">
                            {getStudentName(item.consultation)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.recommendation !== "NA" ? (
                        <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                          <h4 className="text-[#122064cc] text-[18px] font-medium">
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) =>
                                    item.pageKey === "label_recommendation"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_recommendation"
                                  )[0]?.keyValue
                                : "Recommendation"
                              : "Recommendation"}
                          </h4>
                          <p className="text-[#122064ab] text-[16px] font-regular">
                            {/* {item.recommendation} */}
                            {getStudentName(item.recommendation)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.refer !== "NA" ? (
                        <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                          <h4 className="text-[#122064cc] text-[18px] font-medium">
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "txt_refer"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "txt_refer"
                                  )[0]?.keyValue
                                : "Refer"
                              : "Refer"}
                          </h4>
                          <p className="text-[#122064ab] text-[16px] font-regular">
                            {item.refer}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}

                <div className="lg:px-[80px] px-[40px]  py-[20px] border-t-[10px] !border-[#F4F4FA] hidden">
                  <div className="relative h-[25vh] z-[2]">
                    <img
                      src="../Images/report-icon.svg"
                      className="absolute left-[-80px] h-[100%] z-[99] hidden"
                    />
                    <div className="flex flex-col justify-around items-center h-[100%]">
                      <p className="font-semibold ">
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "msg_fill_screen"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "msg_fill_screen"
                              )[0]?.keyValue
                            : "Let us fill Profiler form to understand students’ strengths and abilities"
                          : "Let us fill Profiler form to understand students’ strengths and abilities"}
                      </p>
                      <button
                        className={`bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white bg-gradient-to-r from-[#3a3e58] to-[#777f94] cursor-pointer`}
                        onClick={() => {
                          updateStudentStatus();
                        }}
                      >
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_screening_2"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "label_screening_2"
                              )[0]?.keyValue
                            : "Profiler Form"
                          : "Profiler Form"}
                      </button>
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          setShowDashboard(true);
                          navigate("/user/screening-dashboard");
                        }}
                      >
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_will_do_later"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "label_will_do_later"
                              )[0]?.keyValue
                            : "No, I will do later"
                          : "No, I will do later"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-[80%] w-full">
          <Loader type="spinningBubbles" color="#2e376c" />
        </div>
      )}
    </>
  );
};

export default Screening1Report;
