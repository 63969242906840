import {
  combineReducers,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import screening from "../../services/screening.services";

export const getOccupation = createAsyncThunk(
  "getOccupation",
  async (languageId) => {
    try {
      const res = await screening.getOccupation(languageId);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const occuptionSlice = createSlice({
  name: "getOccupation",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOccupation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getOccupation.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getOccupation.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const occuptionSelector = (state) => {
  return state.screeningReducer.occuptionSlice;
};

export const getBoard = createAsyncThunk("getBoard", async (languageId) => {
  try {
    const res = await screening.getBoard(languageId);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const boardSlice = createSlice({
  name: "getOccupation",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoard.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getBoard.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getBoard.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const boardSelector = (state) => {
  return state.screeningReducer.boardSlice;
};

export const getSchoolType = createAsyncThunk(
  "getSchoolType",
  async (languageId) => {
    try {
      const res = await screening.getSchoolType(languageId);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const schoolTypeSlice = createSlice({
  name: "getSchoolType",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolType.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSchoolType.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getSchoolType.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const schoolTypeSelector = (state) => {
  return state.screeningReducer.schoolTypeSlice;
};

export const getEducationType = createAsyncThunk(
  "getEducationType",
  async (languageId) => {
    try {
      const res = await screening.getEducationStatus(languageId);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const educationTypeSlice = createSlice({
  name: "getEducationType",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEducationType.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEducationType.fulfilled, (state, action) => {
        const { payload } = action;

        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getEducationType.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const educationTypeSelector = (state) => {
  return state.screeningReducer.educationTypeSlice;
};

const screeningReducer = combineReducers({
  occuptionSlice: occuptionSlice.reducer,
  boardSlice: boardSlice.reducer,
  schoolTypeSlice: schoolTypeSlice.reducer,
  educationTypeSlice: educationTypeSlice.reducer,
});

export default screeningReducer;
