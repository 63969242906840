import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { fetchInstruction } from "../../../utils/instruction/InstructionApi";

const InterventionMarkLevels = (props) => {
  const { setOpenMarksInfo } = props;

  const language_id = JSON.parse(localStorage.getItem("l_id"));
  const [getMarkLevels, setMarkLevels] = useState();
  const parse = require("html-react-parser");

  const getMarkLevelsInfo = (language_id) => {
    fetchInstruction("Intervention Level Instruction", language_id).then(
      (response) => {
        if (response.response[0] === undefined) {
          getMarkLevelsInfo(1);
        } else {
          setMarkLevels(response.response[0]);
        }
      }
    );
  };
  useEffect(() => {
    getMarkLevelsInfo(language_id);
  }, [language_id]);

  return (
    <>
      <div className="bg-[#F4F4FA] h-full">
        <div className="w-4/4 m-auto">
          <div className="gradient-96 rounded-t-[30px] flex justify-start items-center py-4 px-6">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ color: "#fff" }}
              size="xl"
              onClick={() => {
                // navigate(-1);
                setOpenMarksInfo(false);
              }}
              className="mr-2 cursor-pointer"
            />
            <h2 className="font-semibold text-[22px] text-[#fff]">
              How to mark levels?
            </h2>
          </div>
          <div className="bg-[#fff] rounded-b-[30px] text-left px-6 py-4 h-[calc(100vh_-_194px)]">
            {getMarkLevels?.description && parse(getMarkLevels?.description)}
          </div>
        </div>
      </div>
    </>
  );
};

export default InterventionMarkLevels;
