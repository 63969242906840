import React, { useEffect, useRef, useState } from "react";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Report from './Report';
// import {
//     screeningPhaseOneAnswer,
//     screeningPhaseOneQA,
// } from './Api/ScreeningApi';
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import Modal from "../../../modal/Modal";
import { useLocation, useNavigate } from "react-router-dom";
// import ScreeningDashboard from './ScreeningDashboard';
import { useMediaQuery } from "react-responsive";
import {
  GetDomainProfilerForm,
  PostScreeningProfilerForm,
  getAdvanceScreeningDomain,
  getAdvanceScreeningQnA,
  getProfilerFormQuestionAnswers,
  postAdvanceScreeningData,
} from "../Api/ScreeningApi";
import puzzleSmile from "../../../../assets/images/puzzle_smile.svg";
import { fetchInstruction } from "../../../../utils/instruction/InstructionApi";

const AdvanceScreeningQA = () => {
  const [showDashboard, setShowDashboard] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const lastQuestionRef = useRef(null);
  const languageId = localStorage.getItem("l_id");
  // const navigation = useNavigate();
  const [domainCategoryData, setDomainCategoryData] = useState(1);
  const [domainIdArray, setDomanIdArray] = useState([]);

  const [report, setReport] = useState(true);
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState([]);
  const [subQuestion, setSubqestion] = useState([]);
  const [subQuestionIndex, setSubqestionIndex] = useState();
  const [domainMaster, setDomainMaster] = useState([]);
  const [isShowMultiSaveButton, setIsShowMultiSaveButton] = useState(false);
  const [length, setLength] = useState(0);
  const [advanceScrrencount, setAdvanceScreeningCount] = useState(0);
  const [instruction, setInstruction] = useState();

  const [index, setIndex] = useState(1);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [answerSheet, setAnswerSheet] = useState({
    studentId: location?.state?.studentID,
    screeningQuestionOption: [],
    languageId: languageId,
    screeningCategoryId: 3,
    userTypeID: JSON.parse(localStorage.getItem("user")).userTypeId,
    userID: location?.state?.userID,
    domainMasterId: domainMaster,
  });

  const handleSubmit = () => {
    
    postAdvanceScreeningData(answerSheet)
      .then((res) => {
        navigate("/user/advanceScreeningReport", {
          state: location.state,
        });
      })
      .catch((error) => {});
  };

  const scrollToBottom = () => {
    lastQuestionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const currentQues = answerSheet.screeningQuestionOption.length - length;
  const totalQues = questionAnswer?.length;
  const progress = (currentQues / totalQues) * 100;

  const [showModal, setShowModal] = useState(false);
  // Modal 2 //
  const [showModal2, setShowModal2] = useState(false);
  //   Submit Modal //
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [mobileOn, setMobileOn] = useState(false);

  const getdomainAdvanceScreening = () => {
    const { studentID } = location.state;

    getAdvanceScreeningDomain(studentID, 3, languageId)
      .then((res) => {
        setDomainMaster(res);

        const idOfDomain = res.map(
          (item) => item.advanceScreeningDomainCategoryID
        );
        setDomanIdArray(idOfDomain);
      })
      .catch((error) => {});
  };
  
  const getQuestionAnswer = (id = 17) => {
    const languageId = localStorage.getItem("l_id");
    const gradeId = location.state.gradeid;

    getAdvanceScreeningQnA(languageId, 3, id, gradeId)
      .then((res) => {
        setTotalQuestion(res);
        const questionAnswer = res.filter((item) => item?.isSubQuestion === 0);
        setQuestionAnswer(questionAnswer);
        setAnswerSheet({
          ...answerSheet,
          screeningQuestionOption: [...answerSheet.screeningQuestionOption],
        });
      })
      .catch((error) => {
        console.log(error, "errrrr");
      });
  };

  const handleAnswer = (item, subItem) => {
    const existingQuestion = answerSheet.screeningQuestionOption.find(
      ({ questionid }) => questionid === item.questionid
    );

    if (existingQuestion) {
      const updatedOptions = answerSheet.screeningQuestionOption.map(
        (option) => {
          if (option.questionid === existingQuestion.questionid) {
            return {
              ...option,
              optionid: subItem.optionid,
            };
          }
          return option;
        }
      );
    
      setAnswerSheet({
        ...answerSheet,
        screeningQuestionOption: updatedOptions,
      });
    } else {
      const finalData = {
        questionid: item.questionid,
        optionid: subItem.optionid,
        domainCategoryID: item.domainCategoryMasterID,
        domainMasterID: item.domainMasterID,
      };

      setAnswerSheet({
        ...answerSheet,
        screeningQuestionOption: [
          ...answerSheet.screeningQuestionOption,
          finalData,
        ],
      });
      if (subItem.isSubQuestion === 1) {
        const findOptionSubQuestion = totalQuestion?.filter(
          (item) => item.questionid === subItem.subQuestionID
        );
        const findOptionSubQuestionIndex = totalQuestion?.findIndex(
          (item) => item.questionid === subItem.subQuestionID
        );
        setSubqestion(findOptionSubQuestion);
        setSubqestionIndex(findOptionSubQuestionIndex);
      } else {
        setSubqestion([]);
        setSubqestionIndex();
      }

      setIndex(index + 1);
    }
  };

  const isSmallScreen = useMediaQuery({ query: "(max-width: 440px)" });

  useEffect(() => {
    isSmallScreen ? setMobileOn(true) : setMobileOn(false);
    // console.log(mobileOn);
  }, [isSmallScreen]);

  useEffect(() => {
    getdomainAdvanceScreening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId]);

  useEffect(() => {
    if (answerSheet.domainMasterId) {
      getQuestionAnswer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSheet.domainMasterId, languageId]);

  useEffect(() => {
    scrollToBottom();
  }, [index]);

  useEffect(() => {
    if (subQuestionIndex) {
      const list =
        subQuestion?.length > 0
          ? [
              ...questionAnswer?.slice(0, subQuestionIndex),
              subQuestion[0],
              ...questionAnswer.slice(subQuestionIndex),
            ]
          : questionAnswer;

      setQuestionAnswer(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subQuestionIndex, languageId]);

  const resetValues = () => {
    setQuestionAnswer([]);
    setIndex(1);
    setAdvanceScreeningCount(advanceScrrencount + 1);
    const count = advanceScrrencount + 1;
    const id = domainMaster[count]?.advanceScreeningDomainCategoryID;
    setLength(answerSheet.screeningQuestionOption.length);
    getQuestionAnswer(id);
  };

  // instruction api calling //
  const getAdvanceInstruction = (l_id) => {
    fetchInstruction("Advance_Screening", l_id).then((response) => {
      if (response.response[0] === undefined) {
        getAdvanceInstruction(1);
      } else {
        setInstruction(response.response[0]);
      }
    });
  };
  useEffect(() => {
    getAdvanceInstruction(languageId);
  }, [languageId]);

  return (
    <>
      {!showDashboard ? (
        <>
          {report ? (
            <div className="bg-[#F4F4FA] md:py-[30px] lg:px-[50px] px-[0px] md:h-[calc(100%_-_9%)] h-[calc(100%_-_10%)] overflow-y-scroll no-scrollbar">
              <div className="bg-[#fff] rounded-[25px] h-[100%] ">
                <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#DFE5FF] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex flex-row justify-between items-center lg:px-[50px] px-[20px] pt-[10px]">
                  <h2 className="text-[#3C3F4F] font-semibold text-left lg:text-[22px] text-[18px] py-[20px]  mt-[] ">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "key_advance_screening"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "key_advance_screening"
                          )[0]?.keyValue
                        : "Advance Screening"
                      : "Advance Screening"}
                  </h2>
                  <div className="hidden flex-row items-center md:flex">
                    <p>
                      {domainCategoryData}/{domainMaster.length}&nbsp;
                    </p>
                    <ProgressBar
                      percent={progress}
                      filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                      width={`${mobileOn === true ? "75px" : "150px"}`}
                      position={answerSheet.screeningQuestionOption.length}
                      index={answerSheet.screeningQuestionOption.length}
                    />
                    <p className="lg:text-[18px] text-[18px] text-[#3C3F4F] font-semibold mx-2">
                      {answerSheet.screeningQuestionOption.length - length}/
                      {questionAnswer?.length}{" "}
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "text_questions"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "text_questions"
                            )[0]?.keyValue
                          : "Questions"
                        : "Questions"}
                    </p>
                  </div>

                  <div className="flex flex-row">
                    <img
                      src="../Images/book.svg"
                      alt=""
                      className="md:mr-[40px] mr-[10px] w-[20px] cursor-pointer"
                      onClick={() => setShowModal(true)}
                    />
                    <p
                      className="bg-[#f6f7fa] rounded-full py-[8px] w-[40px] h-[40px] cursor-pointer"
                      onClick={() => setShowModal2(true)}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        style={{ color: "#3c3f4f" }}
                      />
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center md:hidden">
                  <p className="mx-2">
                    {domainCategoryData} of {domainMaster.length}
                  </p>{" "}
                  <ProgressBar
                    percent={progress}
                    filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                    width={`${mobileOn === true ? "75px" : "150px"}`}
                    position={answerSheet.screeningQuestionOption.length}
                    index={answerSheet.screeningQuestionOption.length}
                  />
                  <p className="lg:text-[18px] text-[18px] text-[#3C3F4F] font-semibold mx-2">
                    {answerSheet.screeningQuestionOption.length - length}/
                    {questionAnswer?.length}
                  </p>
                </div>
                <div className="border-t-2 h-[calc(100%_-_15%)] py-[50px] bg-puzzle">
                  <div className="w-[85%] lg:w-[100%] md:ml-[50px] ml-[30px] md:pr-[60px] pr-[30px] overflow-y-scroll float-rights flex flex-col relative h-[100%]">
                    {questionAnswer &&
                      questionAnswer?.slice(0, index)?.map((item, i) => (
                        <div className="mb-[50px] flex flex-col justify-end">
                          <div className=" rounded-tl-[4px] rounded-r-[20px] rounded-bl-[20px] p-5">
                            <p className="text-left text-[#101942] font-medium">
                              {item?.questionname}
                              {item?.filePath === "" ? (
                                ""
                              ) : (
                                <>
                                  {item?.fileExtn === ".mp4" ? (
                                    <video
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "300px",
                                      }}
                                      controls
                                    >
                                      <source
                                        src={`https://admin.projectinclusion.in${item?.filePath.replace(
                                          "~",
                                          ""
                                        )}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    <img
                                      src={`https://admin.projectinclusion.in${item?.filePath.replace(
                                        "~",
                                        ""
                                      )}`}
                                    />
                                  )}
                                </>
                              )}
                            </p>
                          </div>
                          <div className="flex flex-row justify-end flex-wrap">
                            {questionAnswer &&
                              questionAnswer[i].questionoptions?.map(
                                (subItem, subIndex) => {
                                  return (
                                    <div
                                      className=" float-right mt-[30px]"
                                      key={subIndex}
                                    >
                                      <button
                                        className={`${
                                          answerSheet.screeningQuestionOption[
                                            length + i
                                          ]?.optionid === subItem.optionid
                                            ? "bg-[#F3F6FF] text-[#3650C9] border-[#F3F6FF]"
                                            : "bg-[#fff]"
                                        } border-[#DCDDE3] border-[2px] hover:bg-[#F3F6FF] text-[16px] text-[#101942] rounded-[10px] py-2 px-4 ml-[20px] font-light`}
                                        onClick={() => {
                                          handleAnswer(item, subItem);
                                          if (item.questionTypeID === 1) {
                                            setIsShowMultiSaveButton(true);
                                          }
                                        }}
                                      >
                                        {subItem.optionname}
                                      </button>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      ))}

                    <div ref={lastQuestionRef}></div>
                    {questionAnswer?.length ===
                    answerSheet.screeningQuestionOption.length - length ? (
                      <button
                        className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] md:px-[80px] px-[40px] text-white mb-[20px]"
                        onClick={() => {
                          // handleSubmit();
                          if (domainCategoryData === domainMaster.length) {
                            setShowSubmitModal(true);
                          } else {
                            setDomainCategoryData(domainCategoryData + 1);
                            resetValues();
                          }
                        }}
                        // onClick={() => setShowSubmitModal(true)}

                        // item?.isScreeningCompleted === 1
                      >
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "key_save_continue"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_save_continue"
                              )[0]?.keyValue
                            : "Save & Continue"
                          : "Save & Continue"}
                        {/* Save & Proceed */}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // <Report
            //     studentReport={studentReport}
            //     setStudForm={setStudForm}
            //     // setShowQna={setShowQna}
            // />
            ""
          )}
        </>
      ) : (
        // <ScreeningDashboard />
        ""
      )}

      {/* Modal Component */}
      <Modal
        onClick={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
        visible={showModal}
      >
        <div>
          {/* Modal Content */}
          <div className=" lg:w-5/12 md:w-8/12 w-10/12 m-auto ">
            <div className="flex justify-between bg-gradient-to-r from-[#2C3EA2] to-[#192867] rounded-t-[30px] items-center px-6 py-4">
              <h2 className="text-xl font-semibold  text-[#fff]   text-left">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "key_advance_screening"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "key_advance_screening"
                      )[0]?.keyValue
                    : "Advance Screening"
                  : "Advance Screening"}{" "}
              </h2>
              <p
                className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
                onClick={() => setShowModal(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  style={{ color: "#2C3EA2" }}
                />
              </p>
            </div>
            <div className="bg-[#fff] px-6 py-6 rounded-b-[30px]">
              <h5 className="text-left">
                {" "}
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "srneLabel"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "srneLabel"
                      )[0]?.keyValue
                    : "Instructions"
                  : "Instructions"}
              </h5>
              <ul className="list-disc px-6 text-left">
                <li>{instruction?.description}</li>
              </ul>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center hidden"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Exit Modal */}
      <Modal
        visible={showModal2}
      >
        <div>
          {/* Modal Content */}
          <div className=" w-12/12 m-auto ">
            <div className="">
              
            </div>
            <div className="bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center">
              <img src="../Images/sad_puzzle.svg" alt="Puzzle sad Image" />
              <h5 className="font-semibold text-[20px] my-2">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "key_want_to_leave"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_want_to_leave"
                      )[0]?.keyValue
                    : "Want to Leave?"
                  : "Want to Leave?"}
              </h5>
              <p className="w-[80%]">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) =>
                        item.pageKey ===
                        "key_dont_worry_you_can_continue_from_where_you_left"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) =>
                          item.pageKey ===
                          "key_dont_worry_you_can_continue_from_where_you_left"
                      )[0]?.keyValue
                    : "Don't worry you can continue from where you left."
                  : "Don't worry you can continue from where you left."}
              </p>
              <div className="flex justify-around w-[100%]">
                <button
                  // onClick={() => setShowQna(false)}
                  onClick={() => {
                    setShowDashboard(true);
                    navigate("/user/screening-dashboard");
                    setShowModal2(false);
                  }}
                  className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "key_leave"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "key_leave"
                        )[0]?.keyValue
                      : "Leave"
                    : "Leave"}
                </button>
                <button
                  onClick={() => setShowModal2(false)}
                  className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "label_cancel"
                      )[0].keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_cancel"
                        )[0].keyValue
                      : "Cancel"
                    : "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* ===== Submit Modal ===== */}
      {showSubmitModal && (
        <Modal
          onClick={() => setShowSubmitModal(false)}
          onClose={() => setShowSubmitModal(false)}
          visible={showSubmitModal}
        >
          <div>
            {/* Modal Content */}
            <div className=" w-full md:w-[450px] m-auto rounded-[30px] ">
              <div className="">
                {/* <h2 className="text-xl font-semibold bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] text-left">
              Screening 1
            </h2> */}
              </div>
              <div className="bg-[#fff] rounded-[30px] ">
                <div className="px-6 py-[40px] flex flex-col justify-center items-center">
                  {/* <img
                    src={puzzleSmile}
                    alt=""
                    className="md:w-[30%] w-[50%]"
                  /> */}
                  <h5 className="font-semibold md:text-[22px] text-[20px] text-[#101942]">
                    Want to submit?
                  </h5>
                  <p className="w-[80%] text-[#3C57AD] font-light text-[14px]">
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) =>
                            item.pageKey ===
                            "key_dear_teacher_would_you_like_to_submit_your_responses"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) =>
                              item.pageKey ===
                              "key_dear_teacher_would_you_like_to_submit_your_responses"
                          )[0]?.keyValue
                        : "Dear teacher, Would you like to submit your responses?"
                      : "Dear teacher, Would you like to submit your responses?"}
                  </p>
                  <div className="flex justify-around w-[100%]">
                    <button
                      // onClick={() => setShowQna(false)}
                      onClick={() => {
                        setShowSubmitModal(false);
                      }}
                      className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                    >
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_check_again"
                          )[0].keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_check_again"
                            )[0].keyValue
                          : "Check Again"
                        : "Check Again"}
                    </button>
                    <button
                      className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "submit_btn"
                          )[0].keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "submit_btn"
                            )[0].keyValue
                          : "Submit"
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AdvanceScreeningQA;
