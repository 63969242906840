import React, { useState } from "react";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import ScreeningDashboard from "./ScreeningDashboard";
import { useNavigate } from "react-router-dom";
import { updateStudentScreeningStatus } from "./Api/ScreeningApi";
import congratulationIcon from "../../../assets/images/icon_congra.png";

const Report = (props) => {
  const { studentReport , state, noReport, studentId } = props;
  const [showDashboard, setShowDashboard] = useState(false);

  const getTranslateText = useSelector(getTranslatedTextSelector);
  const studentDetails = JSON.parse(localStorage.getItem("student_detail"));
  const studentNames = studentDetails?.studentName;

  const navigate = useNavigate();
 
  const getStudentName = (consult) => {
   
    const addStudentName = consult?.replace("<StudentName>", studentNames);
    
    return addStudentName;
   
  };


  const updateStudentStatus = async () => {

    updateStudentScreeningStatus(studentId, 2)
      .then((res) => {
        navigate("/user/instruction", {
          state: {
            userID: state?.studentForm?.userId,
            UserID: state?.studentForm?.userId,
            studentID: studentId,
            activeScreeningCategoryID:
              state?.studentForm?.activeScreeningCategoryID + 1,
            currentScreeningStatus: state?.studentForm?.currentScreeningStatus,
            gradeid: state?.studentForm?.gradeId,
          },
        });
        localStorage.setItem("tabsId", 2);
      })
      .catch((error) => {
        console.log(error, "errroor");
      });
  };
  return (
    <>
      {!showDashboard ? (
        <>
          {noReport === true ? (
            <>
              <div className="bg-[#F4F4FA] py-[30px] lg:px-[80px] h-[calc(100%_-_9%)]">
                <div className="bg-[#fff] rounded-[25px] h-[100%] p-[20px] mx-[20px]">
                  <div className="flex flex-col items-center pt-[20px] md:w-1/3 w-2/3 m-auto">
                    <img src={congratulationIcon} className="w-[80%]" />
                    <h2 className="pt-[20px] text-[#28a4f3] text-[26px] font-semibold">
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_cong"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_cong"
                            )[0]?.keyValue
                          : "Congratulations"
                        : "Congratulations"}
                    </h2>
                    <p className="pt-[10px] text-[#000] text-[18px]">
                      {studentDetails?.studentName}{" "}
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_age_app"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_age_app"
                            )[0]?.keyValue
                          : "is showing age appropriate development in all the domains"
                        : "is showing age appropriate development in all the domains"}
                    </p>
                  </div>
                  <button
                    type="submit"
                    className="bg-[#101942] text-[#fff] py-3 md:py-2 px-24 rounded-md m-auto flex justify-center relative mt-6 md:w-[45%] w-[80%]"
                    onClick={() => {
                      setShowDashboard(true);
                      navigate("/user/screening-dashboard");
                    }}
                  >
                    {getTranslateText?.data.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "btn_proceed"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "btn_proceed"
                          )[0]?.keyValue
                        : "Proceed"
                      : "Proceed"}
                    {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
                    <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
                      <img
                        src="../right-arrow.png"
                        className="w-[8px] h-[10px]"
                      />
                    </span>
                  </button>{" "}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bg-[#F4F4FA] py-[30px] lg:px-[80px] h-[calc(100%_-_9%)]">
                <div className="bg-[#fff] rounded-[25px] h-[100%] ">
                  <div className="clsnames rounded-[20px] bg-gradient-to-b from-[#DFE5FF] from-[0%] via-[#fff] via-[30%] to-[#fff] to-[70%] flex flex-row justify-between items-center lg:px-[50px] px-[40px] pt-[10px]">
                    <h2 className="text-[#3C3F4F] font-semibold text-left text-[24px] py-[20px]  ">
                      {getTranslateText?.data.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "label_scrn_report"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "label_scrn_report"
                            )[0]?.keyValue
                          : "Screening Report"
                        : "Screening Report"}
                    </h2>
                  </div>
                  <div className="border-t-2 overflow-y-scroll h-[calc(100%_-_12vh)]  ">
                    {studentReport?.map((item, index) => (
                      <div className="lg:mx-[80px] mx-[40px] py-[20px]">
                        <h3 className="text-[#2DA3EF] text-[18px] font-semibold text-left mb-2">
                          {item?.domainDescription}
                        </h3>
                        <div>
                          {item.consultation !== "NA" ? (
                            <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                              <h4 className="text-[#122064cc] text-[18px] font-medium">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_consult"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey === "label_consult"
                                      )[0]?.keyValue
                                    : "Consult"
                                  : "Consult"}
                              </h4>
                              <p className="text-[#122064ab] text-[16px] font-regular">
                                {getStudentName(item.consultation)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {item.recommendation !== "NA" ? (
                            <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                              <h4 className="text-[#122064cc] text-[18px] font-medium">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) =>
                                        item.pageKey === "label_recommendation"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) =>
                                          item.pageKey ===
                                          "label_recommendation"
                                      )[0]?.keyValue
                                    : "Recommendation"
                                  : "Recommendation"}
                              </h4>
                              <p className="text-[#122064ab] text-[16px] font-regular">
                                {/* {item.recommendation} */}
                                {getStudentName(item.recommendation)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {item.refer !== "NA" ? (
                            <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                              <h4 className="text-[#122064cc] text-[18px] font-medium">
                                {getTranslateText?.data.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "txt_refer"
                                    )[0]?.keyValue
                                    ? getTranslateText?.data?.filter(
                                        (item) => item.pageKey === "txt_refer"
                                      )[0]?.keyValue
                                    : "Refer"
                                  : "Refer"}
                              </h4>
                              <p className="text-[#122064ab] text-[16px] font-regular">
                                {item.refer}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))}

                    {/* <div className="px-[80px] py-[20px] border-t-[10px] !border-[#F4F4FA]">
              <h3 className="text-[#2DA3EF] text-[18px] font-semibold text-left mb-2">
                Domain Name
              </h3>
              <div>
                {domainNameContent.map((items) => (
                  <div className="bg-[#F6F8FC] p-3 rounded-[8px] mb-4 text-left border-[#1220661A] border-[1px]">
                    <h4 className="text-[#122064cc] text-[18px] font-medium">
                      {items.heading}
                    </h4>
                    <p className="text-[#122064ab] text-[16px] font-regular">
                      {items.desc}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}
                    <div className="lg:px-[80px] px-[40px]  py-[20px] border-t-[10px] !border-[#F4F4FA]">
                      <div className="relative h-[25vh] z-[2]">
                        <img
                          src="../Images/report-icon.svg"
                          className="absolute left-[-80px] h-[100%] z-[99] hidden"
                        />
                        <div className="flex flex-col justify-around items-center h-[100%]">
                          <p className="font-semibold ">
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "msg_fill_screen"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "msg_fill_screen"
                                  )[0]?.keyValue
                                : "Let us fill Profiler form to understand students’ strengths and abilities"
                              : "Let us fill Profiler form to understand students’ strengths and abilities"}
                          </p>
                          <button
                            className={`bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white  cursor-pointer `}
                            onClick={() => {
                              updateStudentStatus();
                            }}
                          >
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_screening_2"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_screening_2"
                                  )[0]?.keyValue
                                : "Profiler Form"
                              : "Profiler Form"}
                          </button>
                          <p
                            className="cursor-pointer"
                            onClick={() => {
                              setShowDashboard(true);
                              navigate("/user/screening-dashboard");
                            }}
                          >
                            {getTranslateText?.data.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) =>
                                    item.pageKey === "label_will_do_later"
                                )[0]?.keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "label_will_do_later"
                                  )[0]?.keyValue
                                : "No, I will do later"
                              : "No, I will do later"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <ScreeningDashboard />
      )}
    </>
  );
};

export default Report;
