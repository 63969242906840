import home from "../../assets/images/home-icon.svg";
import whiteHome from "../../assets/images/home-white-icon.svg";

import courses from "../../assets/images/courses-icon.svg";
import whiteCourses from "../../assets/images/courses-white-icon.svg";

import certificate from "../../assets/images/certificate-icon.svg";
import whiteCertificate from "../../assets/images/certificate-white-icon.svg";
import settings from "../../assets/images/settings-icon.svg";
import whiteScreening from "../../assets/images/screening-icon.svg";
import screening from "../../assets/images/screening-grey-icon.svg";
import InterventionIcon from "../../assets/images/intervention_icon.svg";
import FAQ from "../../assets/images/faq.svg";

export const Menu = [
  // {
  //   title: "Dashboard",
  //   iconGrey: home,
  //   iconWhite: whiteHome,
  // },
  // {
  //   title: "Courses",
  //   iconGrey: courses,
  //   iconWhite: whiteCourses,
  //   path: "/user/course",
  // },
  // {
  //   title: "Certificate",
  //   iconGrey: certificate,
  //   iconWhite: whiteCertificate,
  //   path: "/user/certificate",
  // },
  // {
  //   title: "Screening",
  //   iconGrey: screening,
  //   iconWhite: whiteScreening,
  //   path: "/user/screening",
  // },
  // // {
  // //   title: "Settings",
  // //   icon: settings,
  // // },
  // {
  //   title: "Intervention",
  //   iconGrey: screening,
  //   iconWhite: whiteScreening,
  //   path: "/user/intervention",
  // },

  {
    id: 1,
    title: "Home",
    icon: whiteHome,
    path: "/user/dashboard",
    isSelected: false,
    iconGrey: home,
    iconWhite: whiteHome,
  },
  {
    id: 2,
    title: "Courses",
    isSelected: false,
    iconGrey: courses,
    iconWhite: whiteCourses,
    path: "/user/course",
  },
  {
    id: 3,
    isSelected: false,
    title: "Certificate",
    iconGrey: certificate,
    iconWhite: whiteCertificate,
    path: "/user/certificate",
    disabled: false,
  },

  {
    id: 4,
    title: "Screening",
    icon: whiteHome,
    path: "/user/screening",
    isSelected: false,
    iconGrey: screening,
    iconWhite: whiteScreening,
    children: [
      {
        title: "Pesonal Details",
        path: "/user/student-profile",
        parentId: 4,
        id: 1,
      },
      {
        title: "Screening Dashboard",
        path: "/user/screening-dashboard",
        parentId: 4,
        id: 2,
      },
      {
        title: "Add Student Form",
        path: "/user/add_student_form",
        parentId: 4,
        id: 3,
      },
      {
        title: "Advance Screening",
        path: "/user/advance-screening",
        parentId: 4,
        id: 4,
      },
      {
        title: "Profiler Form",
        path: "/user/profiler-form",
        parentId: 4,
        id: 5,
      },
      {
        title: "Screening 1 Report",
        path: "/user/screening1-report",
        parentId: 4,
        id: 6,
      },
      {
        title: "Advance Screening Report",
        path: "/user/advance_screening_report",
        parentId: 4,
        id: 7,
      },
    ],
  },
  {
    id: 5,
    isSelected: false,
    title: "Intervention",
    iconGrey: InterventionIcon,
    iconWhite: InterventionIcon,
    path: "/user/intervention",
    children: [
      {
        title: "Intervention Domains",
        path: "/user/intervention_domains",
        parentId: 5,
        id: 1,
      },
    ],
  },
  {
    id: 6,
    isSelected: false,
    title: "FAQ",
    iconGrey: FAQ,
    iconWhite: FAQ,
    path: "https://faq.projectinclusion.in/",
    disabled: false,
  },
];



