import { Navigate, Outlet } from "react-router-dom";
import Header from "../utils/Header/Header";
import SideBar from "../utils/SideBar/SideBar";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const UserLayout = () => {
  const [isLoggedIn] = useState(localStorage.getItem("token"));
  const [hideSidebar, setHideSidebar] = useState(true);
  const mobileScreen = useMediaQuery({ query: `(max-width: 440px)` });

  const isProfileCompleted = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const requiredFields = [
      "dob",
      "gender",
      // "lastName",
      "stateId",
      "userName",
      "firstName",
      "mobileNo",
      "districtId",
    ];

    if (!user) {
      return false;
    }

    if (user.userTypeId !== 7) {
      requiredFields.push("schoolId");
    }

    return requiredFields.every((field) => user[field]);
  };

  useEffect(() => {
    if (mobileScreen) {
      // you don't need return statement here.
      setHideSidebar(false);
    }
  }, [mobileScreen]);

  if (!isLoggedIn) {
    return <Navigate to="/user/language" />;
  }

  return (
    <>
      <div className="grid  md:grid-cols-4 lg:grid-cols-5 h-screen">
        <SideBar
          hsidebar={hideSidebar}
          tsidebar={{ hideSidebar, setHideSidebar }}
          isUserProfileCompleted={isProfileCompleted()}
        />
        <div
          className={`${
            hideSidebar
              ? "md:col-span-3 lg:col-span-4 md:block h-screen hidden"
              : "col-span-5 overflow-hidden"
          }`}
        >
          {isProfileCompleted() === true ? (
            <Header tsidebar={{ hideSidebar, setHideSidebar }} />
          ) : (
            <></>
          )}

          <Outlet />
        </div>
      </div>
    </>
  );
};

export default UserLayout;
