import Modal from "../../../modal/Modal";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import DashboardImage from "../../../../assets/images/dashboard-image.svg";

import "./styles.css";
import { useSelector } from "react-redux";

const ProceedToSignupModal = (props) => {
  const { show, setShow, handleLoginWithOtp, authentication } = props;

  const getTranslateText = useSelector(getTranslatedTextSelector);

  console.log(getTranslateText);

  const handleCancel = () => {
    setShow(false);
  };

  const handleProceedToSignup = () => {
    setShow(false);
    handleLoginWithOtp("New");
  };
  // key_register_proceed

  return (
    <>
      <Modal visible={show}>
        <div className="wrapper">
          <img src={DashboardImage} alt="" className="w-[40px] m-auto" />
          <p className="welcome-text">
            {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                  (item) => item.pageKey === "welcome_txt"
                )[0]?.keyValue
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "welcome_txt"
                  )[0]?.keyValue
                : "Welcome"
              : "Welcome"}
          </p>

          <p className="welcome-details">
            {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                  (item) => item.pageKey === "key_register_proceed"
                )[0]?.keyValue
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "key_register_proceed"
                  )[0]?.keyValue
                : " Seems like you are not registered with us , please proceed to signup "
              : " Seems like you are not registered with us , please proceed to signup "}
            <span className="mobile-number">({authentication.userName})</span>{" "}
          </p>

          <div className="button-container">
            <button type="button" onClick={handleCancel} className="cancel-btn">
              {getTranslateText?.data?.length > 0
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "label_cancel"
                  )[0]?.keyValue
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "label_cancel"
                    )[0]?.keyValue
                  : "Cancel"
                : "Cancel btn_proceed"}
            </button>
            <button
              type="button"
              onClick={handleProceedToSignup}
              className="proceed-btn"
            >
              {getTranslateText?.data?.length > 0
                ? getTranslateText?.data?.filter(
                    (item) => item.pageKey === "btn_proceed"
                  )[0]?.keyValue
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "btn_proceed"
                    )[0]?.keyValue
                  : "Proceed"
                : "Proceed"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProceedToSignupModal;
