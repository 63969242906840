import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { apiEndPoints } from "../../../../utils/http";
import { baseURL } from "../../../../utils/http/http-common";

function ChangePassword() {
  const navigate = useNavigate();
  const [changePass, setChangePass] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPass: "",
  });
  const getTranslateText = useSelector(getTranslatedTextSelector);

  //Show hide passowrd //
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const togglePassword = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowNewPassword(!showNewPassword);
    }
  };

  //Show hide password end//
  const handleChange = (e) => {
    setChangePass({ ...changePass, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { oldPassword, newPassword, confirmPass } = changePass;
    if (oldPassword === newPassword) {
      toast.warn("New Password should not be same as Current Password");
      return;
    } else if (newPassword !== confirmPass) {
      toast.warn("Password does not match try again");
      return;
    } else {
      try {
        const userId = localStorage.getItem("id");
        const body = { userId, oldPassword, newPassword };
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${baseURL}/Login/ChangeUserPassword`,
          body,
          {headers:{
            'Authorization':`Bearer ${token}`
          }}
        );
        if (response.data.result.status === 1) {
          toast.success("Password Changed successfully");
          setChangePass({
            ...changePass,
            oldPassword: "",
            newPassword: "",
            confirmPass: "",
          });
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          toast.warn(response.data.result.message);
        }

      } catch (error) {
        throw error;
      }
    }
  };

  return (
    <>
      <div className="flex mt-5 ml-5">
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{ color: "#000000" }}
          size="xl"
          onClick={() => navigate(-1)}
          className="mr-5 cursor-pointer"
        />
      </div>
      <div className="my-[30px] py-[50px] px-[50px] lg:w-2/4 xl:w-2/4 w-full m-auto shadow-[0px_7px_29px_0px_rgba(100,100,111,0.2)]">
        <h2 className="text-[26px] text-[#000] font-semibold mb-[20px]">
          {getTranslateText?.data?.length > 0
            ? getTranslateText?.data?.filter(
                (item) => item?.pageKey === "change_password"
              )[0]?.keyValue
            : "Change Password"}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col [&_label]:flex-col [&_label]:flex [&_label]:my-2 text-left">
            <label>
              <p className="text-[#000000ad]">
                {getTranslateText?.data?.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "enter_current_password"
                    )[0]?.keyValue
                  : "Enter your current password"}
              </p>
              <div className="my-3 relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
                <input
                  type={showPassword ? "text" : "password"}
                  name="oldPassword"
                  value={changePass.oldPassword}
                  onChange={handleChange}
                  minLength={6}
                  maxLength={20}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  placeholder={
                    getTranslateText?.data?.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "old_pass_txt"
                        )[0]?.keyValue
                      : "Old password"
                  }
                  className=" bg-[#F6F7FA] w-full !outline-none
                  "
                  required
                />
                <PasswordToggle
                  showPassword={showPassword}
                  setShowPassword={() => togglePassword("password")}
                  className="top-5"
                />
              </div>
            </label>

            <label>
              <p className="text-[#000000ad]">
                {getTranslateText?.data?.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "enter_a_new_password"
                    )[0]?.keyValue
                  : "Enter a new password"}
              </p>
              <div className="my-3 relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={changePass.newPassword}
                  onChange={handleChange}
                  minLength={6}
                  maxLength={20}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  placeholder={
                    getTranslateText?.data?.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "new_password_txt"
                        )[0]?.keyValue
                      : "New password"
                  }
                  className="bg-[#F6F7FA] w-full !outline-none"
                  required
                />
                <PasswordToggle
                  showPassword={showNewPassword}
                  setShowPassword={() => togglePassword("new password")}
                  className="top-5"
                />
              </div>
            </label>
            <label>
              <p className="text-[#000000ad]">
                {getTranslateText?.data?.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "confirm_new_password"
                    )[0]?.keyValue
                  : "Confirm new password"}
              </p>
              <input
                type="password "
                name="confirmPass"
                value={changePass.confirmPass}
                onChange={handleChange}
                minLength={6}
                maxLength={20}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder={
                  getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "hint_reenter_pass"
                      )[0]?.keyValue
                    : "Confirm password"
                }
                className="py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md bg-[#F6F7FA]"
                required
              />
            </label>
          </div>
          <button
            type="submit"
            className="bg-[#101942] text-[#fff] py-3 md:py-2 px-20 rounded-md m-auto flex justify-center relative mt-2 lg:w-[100%] "
            // onClick={handleSubmit}
          >
            {/* {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "btn_proceed"
              )[0].keyValue
            : "Proceed"} */}
            {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                  (item) => item?.pageKey === "change_password"
                )[0]?.keyValue
              : "Change Password"}

            {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
            <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
              <img src="../right-arrow.png" className="w-[8px] h-[10px]" />
            </span>
          </button>
        </form>
      </div>
    </>
  );
}

export default ChangePassword;
