import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import axios from "axios";
import { baseURL } from "../../../utils/http/http-common";
import Modal from "../../modal/Modal";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import whatsappIcon from "../../../assets/images/whatsapp.png";
import DashboardImage from "../../../assets/images/dashboard-image.svg";
import { getBanner, setBanner } from "../../../utils/cookiesmanager";

import logoSbiImage from "../../../assets/images/sbi-logo.png";


import "./DashboardLanguage.css";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const Dashboard = () => {
  const userId = localStorage.getItem("id");
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const getTranslateText = useSelector(getTranslatedTextSelector);

  const [profileChecking, setProfileChecking] = useState();
  const [showModal, setShowModal] = useState("");
  const [, setAdvcScrCompleted] = useState();
  const [bannerData, setBannerData] = useState({});
  const [showBanner, setShowBanner] = useState(false);
  const [serverError, setServerError] = useState(false);

  const isProfileCompleted = () => {
    if (userDetails?.userTypeId !== 7) {
      if (
        // JSON.parse(localStorage.getItem("user"))?.blockId &&
        JSON.parse(localStorage.getItem("user"))?.dob &&
        JSON.parse(localStorage.getItem("user"))?.gender &&
        // JSON.parse(localStorage.getItem("user"))?.lastName &&
        JSON.parse(localStorage.getItem("user"))?.stateId &&
        JSON.parse(localStorage.getItem("user"))?.userName &&
        JSON.parse(localStorage.getItem("user"))?.firstName &&
        JSON.parse(localStorage.getItem("user"))?.mobileNo &&
        JSON.parse(localStorage.getItem("user"))?.districtId &&
        JSON.parse(localStorage.getItem("user"))?.schoolId
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        // JSON.parse(localStorage.getItem("user"))?.blockId &&
        JSON.parse(localStorage.getItem("user"))?.dob &&
        JSON.parse(localStorage.getItem("user"))?.gender &&
        // JSON.parse(localStorage.getItem("user"))?.lastName &&
        JSON.parse(localStorage.getItem("user"))?.stateId &&
        JSON.parse(localStorage.getItem("user"))?.userName &&
        JSON.parse(localStorage.getItem("user"))?.firstName &&
        JSON.parse(localStorage.getItem("user"))?.mobileNo &&
        JSON.parse(localStorage.getItem("user"))?.districtId
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    isProfileCompleted() === false
      ? navigate("/user/profile")
      : navigate("/user/dashboard");
  }, []);
  const [courseStatus, setCourseStatus] = useState(null);

  // Api Call for Course Completion Check //
  const courseCompletionCheck = async (body) => {
    try {
      const result = await axios.post(
        "https://lmsapi.projectinclusion.in/api/Course/GetShowCourseCompleteStatus",
        body
      );

      function courseChecking(result) {
        if (
          result &&
          result.data &&
          result.data.response &&
          result.data.response[0]
        ) {
          if (
            result.data.response[0].isComplete !== undefined &&
            result.data.response[0].isComplete !== 0
          ) {
            return "1";
          } else {
            return "0";
          }
        } else {
          return "0";
        }
      }
      const courseCheck = courseChecking(result);

      localStorage.setItem("courseCompleted", courseCheck);
      setCourseStatus(courseCheck);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Api Calling to checkProfileCompletion on login //
  const profileCheck = async () => {
    // const userId = localStorage.getItem("id");

    try {
      const response = await axios.post(
        `${baseURL}/Login/CheckProfileCompletion?UserID=${userId}`
      );
      // const banner = getBanner();
      setBannerData(response?.data?.response);

      if (!bannerData.homePopup) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }

      setProfileChecking(response?.data?.response?.isCompleted);
      setAdvcScrCompleted(
        response?.data?.response?.isAdvanceScreeningCompleted
      );

      localStorage.setItem(
        "profileCompleted",
        response?.data?.response?.isCompleted
      );
      localStorage.setItem(
        "advanceCompleted",
        response?.data?.response?.isAdvanceScreeningCompleted
      );

      return response.data.response;
    } catch (error) {
      // console.log(error,'errorororor')
      setServerError(true);
      throw error;
    }
  };

  useEffect(() => {
    const storedCourseStatus = localStorage.getItem("courseCompleted");
    if (storedCourseStatus === null || "0") {
      courseCompletionCheck({ partnerSource: "partner", piUserID: userId });
    } else {
      setCourseStatus(storedCourseStatus);
    }
  }, []);

  useEffect(() => {
    profileCheck();
    if (profileChecking === false) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [profileChecking]);

  return (
    <>
      <div className="flex justify-center flex-col items-center p-[100px] max-h-[89vh]">
        <h1 className="text-[#000] mt-5 text-2xl mb-4 hidden">
          {" "}
          {getTranslateText?.data?.length > 0
            ? getTranslateText?.data?.filter(
                (item) => item.pageKey === "welcome_txt"
              )[0]?.keyValue
            : "Welcome"}
        </h1>
        <img
          src={DashboardImage}
          alt="Pi logo"
          className={`lg:w-[30%] md:w-[40%] w-[100%] ${
            showBanner && "hide-dashboard-icon"
          }`}
        />
       
         <img
          src={logoSbiImage}
          alt="Sbi logo"
         className="custom-sbi-image-dashboard-class"
        />
      </div>
      {/* Modal to check profile completed or not */}
      <Modal
        onClick={() => setShowModal(true)}
        onClose={() => setShowModal(true)}
        visible={showModal}
      >
        <div>
          {/* Modal Content */}
          <div className=" md:w-[450px] w-[370px] m-auto ">
            <div className="bg-[#fff] rounded-[20px] p-6">
              <div className=" w-full m-auto">
                <img
                  alt=""
                  src={userDetails.profilePicURL}
                  className={`rounded-[100%] h-[20vh] w-[20vh] m-auto`}
                />
                <h3 className="text-[#222c43] font-medium text-[20px] mt-3">
                  Complete your profile
                </h3>
              </div>
              <div className="[&_p]:text-[222c43] font-normal mt-3">
                <p>Your Profile is incomplete</p>
                <p>Please update profile details</p>
                <button
                  className="bg-[#101942] text-[#fff] rounded-[10px] py-2 px-4 mt-4"
                  onClick={() => {
                    navigate("/user/profile");
                  }}
                >
                  Update Now
                </button>
                <div className="text-left mt-4">
                  <p className="text-[16px] font-medium my-2">Need Help!</p>
                  <div className="text-[#000] text-left ml-2">
                    <div className=" flex justify-between flex-col md:flex-row text-left">
                      <p className="text-[14px]">
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                        support.pi@aurosociety.org
                      </p>
                      <a
                        href="https://wa.me/919355902926"
                        target="_blank	"
                        className="text-[14px] flex "
                      >
                        {" "}
                        {/* <FontAwesomeIcon icon={faWhatsapp} size="xl" /> */}
                        <img src={whatsappIcon} className="w-[20px]" />
                        +91 9355902926
                        {/* Whatsapp */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {bannerData?.homePopup && (
        <Modal visible={showBanner}>
          <div className="banner-wrapper">
            <p
              className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
              onClick={() => {
                setBanner(bannerData.homePopup);
                setShowBanner(false);
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                size="md"
                style={{ color: "#3c3f4f" }}
              />
            </p>
            <img
              alt=""
              src={bannerData.homePopup}
              onClick={() => {
                bannerData?.homePopupRedirectURL !== "#" &&
                  window.open(bannerData?.homePopupRedirectURL, "_blank");
              }}
            />
          </div>
        </Modal>
      )}

      <ServerUpgradtionModal
        show={serverError}
        setShow={setServerError}
        apiCall={profileCheck}
      />
    </>
  );
};

export default Dashboard;
