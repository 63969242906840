import React from "react";
import ReactLoading from "react-loading";

function Loader({ type, color }) {
  return (
    <>
      <ReactLoading type={type} color={color} />
    </>
  );
}

export default Loader;
