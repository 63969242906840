import { apiEndPoints, http } from "../../utils/http";

// const getToken = (data) => {
//   return http.post(apiEndPoints.GET_TOKEN, data);
// };

const getCertificateType = (data) => {
  return http.get(`${apiEndPoints.GET_CERTIFICATE_TYPE}`);
};

const certificate = {
  getCertificateType,
};
export default certificate;
