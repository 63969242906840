import React, { useState } from "react";
import Modal from "../modal/Modal";
import { getTranslatedText } from "../../redux/slices/translatedText/getTranslatedText";
import { getTranslatedTextSelector } from "../../redux/slices/translatedText/getTranslatedText";
import profileIcon from "../../assets/images/student-profile-icon.svg";
import OTPInput from "../user/auth/OTPInput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { decrypt, encrypt } from "../encryption/Encryption";
import {
  sendOtp,
  userLogin,
  verifyOtp,
} from "../../redux/slices/auth/authSlice";
import { toast, ToastContainer } from "react-toastify";
import { deactivateUser } from "../../redux/services/auth.service";
import PasswordToggle from "../../utils/passwordToggle/PasswordToggle";

const DeleteUser = () => {
  const getTranslatedText = useSelector(getTranslatedTextSelector);
  const navigate = useNavigate();
  const [screen, setScreen] = useState(1);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(true);
  const [otp, setOtp] = useState(null);
  const userId = JSON.parse(localStorage.getItem("id")).toString();
  const [message, setMessage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const mobileNo = JSON.parse(localStorage.getItem("user")).mobileNo;

  const togglePassword = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowNewPassword(!showNewPassword);
    }
  };
  // User Verify Password //
  const verifyPswd = async () => {
    const userData = {
      userName: JSON.parse(localStorage.getItem("user")).userName,
      password: encrypt(password),
      userTypeId: JSON.parse(localStorage.getItem("user")).userTypeId,
    };

    dispatch(userLogin(userData)).then((response) => {
      if (response?.payload?.status === 0) {
        setShowMessage(true);
        toast.warn("Incorrect Password");
      } else {
        const mobileNo = JSON.parse(localStorage.getItem("user")).mobileNo;

        // setScreen(3);
        dispatch(sendOtp(decrypt(mobileNo))).then((response) => {

          if (response?.payload?.status === 1) {
            setScreen(3);
            setShowMessage(true);
            toast.success("OTP sent successfully");
          } else {
            setShowMessage(true);
            toast.warn("Something went wrong");
          }
        });
      }
    });
  };

  // VerifyOtp //
  const verify_Otp = async () => {
    // const a = otp?.join("");
    const UserName = decrypt(JSON.parse(localStorage.getItem("user")).mobileNo);
    // const userName = JSON.parse(localStorage.getItem("user")).userName;

    dispatch(verifyOtp({ UserName, otp })).then((response) => {
      if (response?.payload?.status === 1) {

        deactivateUser(userId).then((response) => {

          if (response?.data?.result?.status === 1) {
            setMessage(response?.data?.result?.message);
            // setScreen(4);
            setShowModal(true);
          }
        });
      } else {
        setShowMessage(true);
        toast.warn("Something went wrong");
      }
    });
  };

  //Resend Otp //
  const resendOtp = async () => {
    const mobileNo = JSON.parse(localStorage.getItem("user")).mobileNo;

    dispatch(sendOtp(decrypt(mobileNo))).then((response) => {

      if (response?.payload?.status === 1) {
        setShowMessage(true);
        toast.success("OTP sent successfully");
      } else {
        setShowMessage(true);
        toast.warn("Something went wrong");
      }
    });
  };

  return (
    <>
      <div className=" w-12/12 m-auto ">
        <div>
          <div className=" w-12/12 lg:w-6/12 md:w-8/12 m-auto mt-[100px]">
            <div className="bg-[#fff] px-6 py-6 rounded-[30px] ">
              {screen === 1 ? (
                <>
                  {/* Screen 1 */}
                  <div className="flex flex-col justify-center items-center ">
                    <img
                      src={
                        JSON.parse(localStorage.getItem("user"))
                          .profilePicURL || profileIcon
                      }
                      alt="..."
                      className="rounded-full w-20 h-20"
                    />

                    <h5 className="font-semibold text-[20px] my-2">
                      {getTranslatedText?.data.length > 0
                        ? getTranslatedText?.data?.filter(
                            (item) => item?.pageKey === "key_delete_account"
                          )[0]?.keyValue
                          ? getTranslatedText?.data?.filter(
                              (item) => item?.pageKey === "key_delete_account"
                            )[0]?.keyValue
                          : "Are you sure you want to delete this account?"
                        : "Are you sure you want to delete this account?"}
                    </h5>

                    <p className="pb-5 text-[14px]">
                      {getTranslatedText?.data.length > 0
                        ? getTranslatedText?.data?.filter(
                            (item) => item?.pageKey === "key_delete_message"
                          )[0]?.keyValue
                          ? getTranslatedText?.data?.filter(
                              (item) => item?.pageKey === "key_delete_message"
                            )[0]?.keyValue
                          : "You are requesting to delete all data related to student authentication, quiz proctoring, profile details, bank details and quiz information. \nYou can stop this process by logging back within the next 90 days."
                        : "You are requesting to delete all data related to student authentication, quiz proctoring, profile details, bank details and quiz information. \nYou can stop this process by logging back within the next 90 days."}
                    </p>

                    <div className="flex justify-around w-[100%]">
                      <button
                        onClick={() => {
                          setScreen(2);
                          // setShowModal(false);
                        }}
                        className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      >
                        {getTranslatedText?.data?.length > 0
                          ? getTranslatedText?.data?.filter(
                              (item) => item.pageKey === "key_delete"
                            )[0]?.keyValue
                            ? getTranslatedText?.data?.filter(
                                (item) => item?.pageKey === "key_delete"
                              )[0]?.keyValue
                            : "Delete"
                          : "Delete"}
                      </button>
                      <button
                        // onClick={() => setShowModal(false)}
                        className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                        onClick={() => {
                          navigate("/user/dashboard");
                          // setShowModal(false);
                        }}
                      >
                        {getTranslatedText?.data?.length > 0
                          ? getTranslatedText?.data?.filter(
                              (item) => item.pageKey === "label_cancel"
                            )[0]?.keyValue
                            ? getTranslatedText?.data?.filter(
                                (item) => item?.pageKey === "label_cancel"
                              )[0]?.keyValue
                            : "Cancel"
                          : "Cancel"}
                      </button>
                    </div>
                  </div>
                </>
              ) : screen === 2 ? (
                <>
                  {/* Screen 2 */}
                  <div className="flex flex-col justify-center items-center ">
                    <h5 className="font-semibold text-[20px] my-2">
                      Verify Password
                    </h5>
                    <div className="my-3 relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md w-[90%]">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        className="bg-[#F6F7FA] w-full !outline-none rounded-full"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <PasswordToggle
                        showPassword={showPassword}
                        setShowPassword={() => togglePassword("password")}
                        className="top-5"
                      />
                    </div>

                    <div className="flex justify-around w-[100%]">
                      <button
                        onClick={() => {
                          navigate("/user/dashboard");
                          // setShowModal(false);
                        }}
                        className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      >
                        {getTranslatedText?.data?.length > 0
                          ? getTranslatedText?.data?.filter(
                              (item) => item.pageKey === "label_cancel"
                            )[0]?.keyValue
                            ? getTranslatedText?.data?.filter(
                                (item) => item?.pageKey === "label_cancel"
                              )[0]?.keyValue
                            : "Cancel"
                          : "Cancel"}
                      </button>
                      <button
                        onClick={() => verifyPswd()}
                        className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </>
              ) : screen === 3 ? (
                <>
                  {/* Screen 3 */}
                  <div className="flex flex-col justify-center items-center ">
                    <h5 className="font-semibold text-[20px] my-2">
                      Enter OTP
                    </h5>
                    <div>
                      <p>
                        {getTranslatedText?.data?.length > 0
                          ? getTranslatedText?.data?.filter(
                              (item) => item.pageKey === "error_enter_otp"
                            )[0]?.keyValue
                            ? getTranslatedText?.data?.filter(
                                (item) => item?.pageKey === "error_enter_otp"
                              )[0]?.keyValue
                            : "Enter the OTP sent to your mobile number."
                          : "Enter the OTP sent to your mobile number."}
                      </p>

                      <p>{decrypt(mobileNo)}</p>
                    </div>
                    <div>
                      <OTPInput
                        autoFocus
                        //isNumberInput
                        length={6}
                        className="otpContainer"
                        inputClassName="otpInput"
                        onChangeOTP={
                          setOtp &&
                          ((otp) => {
                            setOtp(otp);
                          })
                        }
                        value=""
                      />
                    </div>

                    <div className="flex flex-col justify-around w-[100%]">
                      <button
                        onClick={() => verify_Otp()}
                        className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      >
                        Verify OTP
                      </button>
                      <button
                        onClick={() => {
                          resendOtp();
                        }}
                        className=" text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      >
                        {getTranslatedText?.data?.length > 0
                          ? getTranslatedText?.data?.filter(
                              (item) => item.pageKey === "resend_otp"
                            )[0]?.keyValue
                            ? getTranslatedText?.data?.filter(
                                (item) => item?.pageKey === "resend_otp"
                              )[0]?.keyValue
                            : "Resend OTP"
                          : "Resend OTP"}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Screen 4 */}
                  <div className="flex flex-col justify-center items-center hidden">
                    <h5 className="font-semibold text-[20px] my-2">
                      Account Successfully Deleted!
                    </h5>
                    <p>
                      You can stop the deletion process by logging back in
                      before {message}
                    </p>

                    <div className="flex justify-around w-[100%]">
                      <button
                        // onClick={() => setShowModal(false)}
                        className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                      >
                        Okay Got It
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        // onClick={() => setShowModal(false)}
        // onClose={() => setShowModal(false)}
        visible={showModal}
      >
        <div>
          {/* Modal Content */}
          <div className=" w-8/12 m-auto ">
            <div className="">
              {/* <h2 className="text-xl font-semibold bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] text-left">
                Screening 1
              </h2> */}
              <div className=" bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center ">
                <h5 className="font-semibold text-[20px] my-2">
                  Account Successfully Deleted!
                </h5>
                <p>
                  You can stop the deletion process by logging back in before{" "}
                  {message}
                </p>

                <div className="flex justify-around w-[100%]">
                  <button
                    onClick={() => {
                      localStorage.clear();
                      navigate("/select/language");
                      setShowModal(false);
                    }}
                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    Okay Got It
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center"></div> */}
          </div>
        </div>
      </Modal>
      {showMessage && (
        <ToastContainer
          pauseOnHover={true}
          autoClose={2000}
          position="top-right"
        />
      )}
    </>
  );
};

export default DeleteUser;
