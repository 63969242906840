import axios from "axios";
import { baseURL } from "../../../utils/http/http-common";
import { apiEndPoints } from "../../../utils/http";

export const GetProfession = async (
  ) => {
    try {
      const response = await axios.get(
        `${baseURL}${apiEndPoints.GET_PROFESSION}`
      );
      
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

export const GetQualification = async (
    id
  ) => {
    try {
      const response = await axios.get(
        `${baseURL}${apiEndPoints.GET_QUALIFICATION}/${id}`
      );
      
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

export const GetSpecialization = async (
    professionId,
    qualificationId
  ) => {
    try {
      const response = await axios.get(
        `${baseURL}${apiEndPoints.GET_SPECIALIZATION}/${professionId}/${qualificationId}`
      );
      
      return response?.data;
    } catch (error) {
      throw error;
    }
  };