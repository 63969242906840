import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "./components/home/HomeLayout";
import UserLayout from "./Layout/UserLayout";
import { privateRoutes } from "./views/PrivateRoutes";
// import { publicRoutes } from "./views/PublicRoutes";
import NotFound from "./components/NotFound/NotFound";
import Language from "./components/Language/Language";

export const routes = createBrowserRouter([
  {
    path: "/user/language",
    element: <Language />,
  },
  {
    path: "/user/login",
    element: <HomeLayout />, 
    // children: publicRoutes,
  },
  {
    path: "/user",
    element: <UserLayout />,
    children: privateRoutes,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
