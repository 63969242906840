import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchInstruction } from "../../../../utils/instruction/InstructionApi";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import Loader from "../../../../utils/loader/Loader";

const InstructionPage = () => {
  const location = useLocation();
  const navigation = useNavigate();
  let language = localStorage.getItem("l_id");
  const [instruction, setInstruction] = useState();
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [loader, setLoader] = useState();

  const getProfilerInstruction = (l_id) => {
    fetchInstruction("Profiler Form Disclaimer", language).then((response) => {
      if (response.response[0] === undefined) {
        language = 1;
        getProfilerInstruction(language);
      } else {
        setInstruction(response.response[0]);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getProfilerInstruction();
  }, [language]);

  const redirectToProfileForm = () => {
    navigation("/user/profiler-form", { state: location.state });
  };

  return (
    <>
      {loader !== true ? (
        <>
          <div className="bg-[#F4F4FA] py-8 h-[calc(100vh_-_9vh)]">
            <div className="md:w-8/12 w-11/12 lg:w-6/12 m-auto rounded-[30px] bg-[#fff] h-full relative">
              <div>
                <h2 className="text-xl font-semibold bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] text-left">
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "label_screening_2"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "label_screening_2"
                        )[0]?.keyValue
                      : "Profiler Form"
                    : "Profiler Form"}
                </h2>
              </div>
              <div className="my-5 px-5">
                <h5 className="text-left text-[#101942] font-medium text-[18px] mb-4  text-[101942cc]">
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "srneLabel"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "srneLabel"
                        )[0]?.keyValue
                      : "Instructions"
                    : "Instructions"}
                </h5>

                <p className="text-left text-[#101942cc] text-[14px] tracking-[0.36px]">
                  {instruction?.description}
                </p>
              </div>
              <button
                // className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white"
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center block m-auto absolute bottom-5 left-[30%] w-[40%]"
                onClick={redirectToProfileForm}
              >
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "key_got_it"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "key_got_it"
                      )[0]?.keyValue
                    : "Got It!"
                  : "Got It!"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-[80%] w-full">
          <Loader type="spinningBubbles" color="#2e376c" />
        </div>
      )}
    </>
  );
};

export default InstructionPage;
