import React, { useEffect, useState } from "react";
import { fetchInstruction } from "../../../utils/instruction/InstructionApi";
import AddStudentForm from "./AddStudentForm";
import Screening1 from "./ScreeningCases/Screening1";
import {
  screeningPhaseCategory,
  screeningPhaseTwoStudentPendingList,
} from "./Api/ScreeningApi";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import ScreeningQA from "./ScreeningQA";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import Loader from "../../../utils/loader/Loader";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const ScreeningDashboard = () => {
  const getTranslateText = useSelector(getTranslatedTextSelector);

  const [studForm, setStudForm] = useState(true);
  const [scrCategoryId, setScrCategoryId] = useState();
  const [data, setData] = useState([]);
  const [serverErrorModal , setServerErrorModal] = useState(false)

  const [tabState, setTabState] = useState();
  const [screeningOneStudentData, setScreeingOneStudentData] = useState([]);
  const [showQna, setShowQna] = useState(false);

  const [profileVisible, setProfileVisible] = useState(false);
  const revierwerId = JSON.parse(localStorage.getItem("userTypeId"));

  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);
  const tab_id = localStorage.getItem("tabsId");

  let language_id = localStorage.getItem("l_id");
  const userId = localStorage.getItem("id");

  const openStudForm = () => {
    setStudForm(false);
  };
  const [state, setState] = useState();

  const getInstruction = (id) => {
    fetchInstruction("second_screening", language_id).then((response) => {

      if (response.response[0] === undefined) {
        language_id = 1;
        console.log(response);
        getInstruction(language_id);
      } else {
        setState(response.response[0]);
        setLoader(false);
      }
    });
  };

  const getScreeningOption = () => {
    screeningPhaseCategory(language_id).then((response) => {
      console.log(response,'response in category')
      if(!response){
        setServerErrorModal(true);
        return ;
      }

      setTabState(response);
      const item = response.filter((item) => item.id === 1);
      setScrCategoryId(item[0].id);
      setLoader(false);
    });
  };

  useEffect(() => {
    getInstruction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language_id]);

  // Tabs Click Function //
  const handleTabClick = (e) => {
    const convertToNum = Number.parseInt(e.target.id);
    setScrCategoryId(convertToNum);
    localStorage.removeItem("tabsId");
    localStorage.setItem("tabsId", convertToNum);
   
  };
  
  // Student Pending List Api //
  const getPendingStudentData = () => {
    if (tab_id === "1") {
      setScrCategoryId(1);
    } else if (tab_id === "2") {
      setScrCategoryId(2);
    } else if (tab_id === "3") {
      setScrCategoryId(3);
    } else {
    }

    screeningPhaseTwoStudentPendingList(
      userId,
      language_id,
      scrCategoryId
    ).then((response) => {
      console.log(response,'responseeee')
     
      if (
        (response.data.error === "GMC0002" && response.data.error === "") ||
        language_id > 3
      ) {
        language_id = 1;
        getPendingStudentData();
      } else {

        setScreeingOneStudentData(response.data.response);
        setLoader(false);
        setLoader1(false);
        setLoader2(false);
      }
    });
  };
  useEffect(() => {

    if (scrCategoryId) {
      getPendingStudentData();
      // setLoader1(false);
    }
  }, [scrCategoryId]);

  useEffect(
    () => {
      getScreeningOption();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {profileVisible ? (
        <PersonalDetails />
      ) : (
        <>
          {studForm ? (
            <>
              {loader !== true ? (
                <>
                  {!showQna ? (
                    <div className="bg-[#F4F4FA] py-[50px] lg:px-[50px] lg:py-[25px] px-[25px] h-[calc(100%_-_9%)] overflow-y-scroll no-scrollbar">
                      <div>
                        {/* Dashboard Header */}
                        <div className="flex justify-between flex-row items-end">
                          <h3 className="flex text-[#99a0bd] font-semibold text-[18px]">
                            {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "dash_label_caseC"
                                )[0].keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "dash_label_caseC"
                                  )[0].keyValue
                                : "My Cases"
                              : "My Cases"}

                            <p className="bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[23px] text-[14px]  text-white py-[2px] px-[15px] ml-3">
                              {screeningOneStudentData.length}
                            </p>
                          </h3>
                          {screeningOneStudentData?.length === 0 ? (
                            ""
                          ) : (
                            <div className="flex ">
                              <div className="text-[#3C57AD] rounded-[10px] border-[#3C57AD] border-[1px] shadow-[0px_4px_16px_#D3D7E452] py-2 px-4 flex mx-3 text-[14px] hidden">
                                Filter
                                <img
                                  src="../Images/filter-icon.svg"
                                  alt="Filter icon"
                                  className="ml-3"
                                />
                              </div>

                             { /* Here we showing add students button only for  for screening 1 tab */}
                             {scrCategoryId === 1
                              &&
                               <button
                               className={`${
                                 revierwerId === 9 ? "hiddens" : ""
                               } bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[10px] text-[12px]  text-white py-[1px] px-[10px] text-[14px]"`}
                               onClick={openStudForm}
                             >
                               {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_add_Student"
                                )[0].keyValue
                                ? getTranslateText?.data?.filter(
                                    (item) =>
                                      item.pageKey === "key_add_Student"
                                  )[0].keyValue
                                : "Add Students"
                              : "Add Students"}
                               &nbsp;+
                             </button>
                             }
                            </div>
                          )}
                        </div>
                        {/* Dashboard Header End */}

                        {/* Dashboard Main */}
                        <div className="md:mt-[60px] mt-[20px] lg:mt-[20px] md:bg-[#fff] rounded-[30px] lg:px-[25px]">
                          <div className="py-[10px] text-left md:ml-[20px] ml-0">
                            {tabState?.map((tabs, i) => {
                              return (
                                <button
                                  disabled={tabs.id === 4}
                                  key={i}
                                  id={tabs.id}
                                  onClick={handleTabClick}
                                  className={`${
                                    (localStorage.getItem("tabsId")
                                      ? +localStorage.getItem("tabsId")
                                      : scrCategoryId) === tabs.id
                                      ? "bg-[#2C3EA2] bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff]"
                                      : " bg-[#E3E3EF] text-[#10194280]"
                                  }  px-6 py-[7px] rounded-[25px] md:mx-[10px] my-2 text-[14px] lg:w-[30%] w-[100%] `}
                                >
                                  {tabs.name}
                                  {/* {tabs.length} */}
                                </button>
                              );
                            })}
                          </div>
                          <div className="md:bg-[#fff] rounded-[30px] h-auto">
                            {tabState?.map((tabs, i) => (
                              <div key={i}>
                                {scrCategoryId === tabs.id && (
                                  <>
                                    {loader1 !== true ? (
                                      <>
                                        {loader2 !== true ? (
                                          <Screening1
                                            showQna={showQna}
                                            setShowQna={setShowQna}
                                            scrData={screeningOneStudentData}
                                            setData={setData}
                                            tabName={tabs.name}
                                            description={state}
                                            studentForm={openStudForm}
                                            setProfileVisible={
                                              setProfileVisible
                                            }
                                            tabId={tabs.id + 1}
                                            tabNumber={tabs.id}
                                            loader={loader}
                                          />
                                        ) : (
                                          <div className="flex justify-center items-center h-[80%]">
                                            <Loader
                                              type="spinningBubbles"
                                              color="#2e376c"
                                            />
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div className="flex justify-center items-center h-[80%]">
                                        <Loader
                                          type="spinningBubbles"
                                          color="#2e376c"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* Dashborad Main End */}
                      </div>
                    </div>
                  ) : (
                    <ScreeningQA
                      setStudForm={setStudForm}
                      studentForm={data}
                      setShowQna={setShowQna}
                    />
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center h-[80%]">
                  <Loader type="spinningBubbles" color="#2e376c" />
                </div>
              )}
            </>
          ) : (
            <AddStudentForm setStudForm={setStudForm} />
          )}
        </>
      )}

     {
      serverErrorModal && 
       <ServerUpgradtionModal show={serverErrorModal} setShow={setServerErrorModal} apiCall = {getScreeningOption} />
     }
    </>
  );
};

export default ScreeningDashboard;
