import React, { useEffect, useState } from "react";
// import Form from "react-bootstrap/Form";
// import { connect } from "react-redux";
// import CustomButton from "../../utils/button/button";
import OTPInput from "../OTPInput";
import "./otp.css";

const OtpScreen = (props) => {
  const {
    handleSubmit,
    setOtp,
    heading,
    mobile,
    handleLoginWithOtp,
    setCount,
    getTextTranslated,
  } = props;
  const [countDown, setCountDown] = useState(30);
  // const handleVerify = (e) => {
  //   e.preventDefault();
  // };
  useEffect(() => {
    const timer =
      countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [countDown]);

  const handleResend = (e) => {
    // handleSubmit && handleSubmit(e, "resendOtp");
    handleLoginWithOtp();
    setCountDown(30);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="Login-Head">
        <h2 className="text-[22px] md:text-[32px] font-medium mb-5">
          {heading && heading}
        </h2>
        <p>
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "error_enter_otp"
              )[0].keyValue
            : "Enter the OTP sent to your mobile number."}
        </p>
        <p className="pt-2">
          {mobile}{" "}
          {/* <button className="text-[#12afff]" onClick={() => setCount(0)}>
            Change
          </button> */}
        </p>
      </div>
      {/* OTP number enter */}
      <OTPInput
        autoFocus
        //isNumberInput
        length={6}
        className="otpContainer"
        inputClassName="otpInput"
        onChangeOTP={
          setOtp &&
          ((otp) => {
            setOtp(otp);
          })
        }
      />
      {/* Verify Button */}
      {/* <button
        text="Verify"
        classname="blue-btn"
        onClick={handleSubmit && ((e) => handleSubmit(e, "otp"))}
      /> */}
      {/* Resend Otp */}
      <div className="flex justify-center mb-[40px]">
        {/* <p className="resend-otp-text"> */}
        {countDown === 0 ? (
          <button
            className="border rounded-md py-1 px-6 border-[#212E6F] text-[#212E6F] "
            onClick={handleResend}
          >
            &nbsp;
            {getTextTranslated?.data?.length > 0
              ? getTextTranslated?.data?.filter(
                  (item) => item.pageKey === "resend_otp"
                )[0].keyValue
              : "Resend OTP"}
          </button>
        ) : (
          <p className="">
            {" "}
            {getTextTranslated?.data?.length > 0
              ? getTextTranslated?.data?.filter(
                  (item) => item.pageKey === "resend_sms"
                )[0].keyValue
              : "Resend in"}{" "}
            {countDown}s
          </p>
        )}
        {/* </p> */}
        {/* <button
          className="border rounded-md py-1 px-6 border-[#212E6F] text-[#212E6F]"
          disabled
        >
          Call me
        </button> */}
      </div>

      {/* <div className="or-seperator">
        <hr />
        <p>or</p>
      </div>

      <div>
        <p className="resend-otp-text">
          <a href="#">Get Via Call</a>
        </p>
      </div> */}
    </div>
  );
};

export default OtpScreen;
