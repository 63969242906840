import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";

import profile from "../../services/profile.service";

export const getGender = createAsyncThunk("getGender", async () => {
  try {
    const res = await profile.getGender();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getGrade = createAsyncThunk("getGrade", async (languageId) => {
  try {
    const res = await profile.getGrade(languageId);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getState = createAsyncThunk("getState", async () => {
  try {
    const res = await profile.getStates();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getDistrict = createAsyncThunk("getDistrtict", async (stateId) => {
  try {
    const res = await profile.getDistrictByStateId(stateId);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getBlock = createAsyncThunk("getBlockById", async (districtId) => {
  try {
    const res = await profile.getBlockByDistrictId(districtId);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getSchool = createAsyncThunk("getSchool", async (data) => {
  try {
    const { stateId, districtId } = data;
    const res = await profile.getSchoolById(stateId, districtId);
    return res.data.response;
  } catch (error) {
    console.log(error);
  }
});

export const updateProfile = createAsyncThunk(
  "updateUserProfile",
  async (data, { rejectWithValue }) => {
    try {
      const res = await profile.updateUserProfile(data);
      return res.data;
    } catch (error) {
      console.log();
      return rejectWithValue(error.message);
    }
  }
);

export const updateProfileNoImage = createAsyncThunk(
  "updateUserProfile",
  async (data, { rejectWithValue }) => {
    try {
      const res = await profile.updateUserProfileNoImage(data);
      return res.data;
    } catch (error) {
      console.log();
      return rejectWithValue(error.message);
    }
  }
);

export const getSchoolByUDISE = createAsyncThunk(
  "get/school/udise",
  async (data, { rejectWithValue }) => {
    try {
      const res = await profile.getSchoolByUdise(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const genderSlice = createSlice({
  name: "getGender",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGender.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getGender.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getGender.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const gradeSlice = createSlice({
  name: "getGrade",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGrade.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getGrade.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getGender.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const stateSlice = createSlice({
  name: "getState",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getState.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getState.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getState.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const districtSlice = createSlice({
  name: "getDistrict",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  reducers: {
    clearDistrictList: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistrict.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDistrict.fulfilled, (state, action) => {
        const { payload } = action;
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getDistrict.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const blockSlice = createSlice({
  name: "get/block",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  reducers: {
    clearBlockList: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlock.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBlock.fulfilled, (state, action) => {
        const { payload } = action;
        // const customizedData = [{ value: 0, label: "other" }].concat(
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        // );
        state.status = "success";
        state.data = state.data.concat(customizedData);
      })
      .addCase(getBlock.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const schoolSlice = createSlice({
  name: "getSchool",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  reducers: {
    clearSchoolList: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchool.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSchool.fulfilled, (state, action) => {
        const { payload } = action;

        /* Remove other option from user profile update
        // const customizedData = [{ value: 0, label: "other" }].concat(
        //   payload?.map((item) => ({
        //     value: item.id,
        //     label: item.name,
        //   }))
        // );
        */
       
        const customizedData = payload?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        state.status = "success";
        state.data = customizedData;
      })
      .addCase(getSchool.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const updateProfileSlice = createSlice({
  name: "updateUserProfile",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const updateProfileNoImageSlice = createSlice({
  name: "updateUserProfile",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfileNoImage.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateProfileNoImage.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(updateProfileNoImage.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const getSchoolByUdiseSlice = createSlice({
  name: "get/udise/school",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSchoolByUDISE.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSchoolByUDISE.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getSchoolByUDISE.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const genderSelector = (state) => {
  return state.profileReducer.genderSlice;
};
export const gradeSelector = (state) => {
  return state.profileReducer.gradeSlice;
};
export const stateSelector = (state) => {
  return state.profileReducer.stateSlice;
};
export const districtSelector = (state) => {
  return state.profileReducer.districtSlice;
};
export const schoolSelector = (state) => {
  return state.profileReducer.schoolSlice;
};
export const blockSelector = (state) => {
  return state.profileReducer.blockSlice;
};

export const getSchoolByUdiseSelector = (state) => {
  return state.profileReducer.getSchoolByUdiseSlice;
};

const profileReducer = combineReducers({
  genderSlice: genderSlice.reducer,
  stateSlice: stateSlice.reducer,
  districtSlice: districtSlice.reducer,
  blockSlice: blockSlice.reducer,
  schoolSlice: schoolSlice.reducer,
  updateProfileSlice: updateProfileSlice.reducer,
  getSchoolByUdiseSlice: getSchoolByUdiseSlice.reducer,
  updateProfileNoImageSlice: updateProfileNoImageSlice.reducer,
  gradeSlice: gradeSlice.reducer,
});
export const { clearBlockList } = blockSlice.actions;
export const { clearDistrictList } = districtSlice.actions;
export const { clearSchoolList } = schoolSlice.actions;
export default profileReducer;
