import { useState } from "react";
import {
  passwordValidation,
  userNameRegx,
} from "../../../../utils/Regex/regex";
import Error from "../../../../utils/error/Error";
import {
  passwordErrorMessage,
  userNameErrorMessage,
} from "../../../../utils/errorMessages/errorMessages";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";
const SetPassword = (props) => {
  const { handleChange, name, getTextTranslated } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const togglePassword = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <div className="my-[0px] lg:my-[10px] text-left md:w-[55%] w-[80%] m-auto">
        <label htmlFor="userName" className="text-[16px] mb-0 text-left">
          {" "}
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "phone_or_username"
              )[0].keyValue
            : "Enter UserName"}
        </label>
        <div className=" relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
          <input
            id="userName"
            name="userName"
            className="bg-[#F6F7FA] w-full !outline-none"
            type={"text"}
            placeholder="Enter Username"
            value={name}
            minLength={3}
            maxLength={20}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value === " ") {
                e.preventDefault();
              } else if (
                !userNameRegx.test(e.target.value) ||
                !e.target.value === " "
              ) {
                handleChange(e, "setPassword");
                setShowErrorMessage(true);
              } else {
                setShowErrorMessage(false);
                handleChange(e, "setPassword");
              }
            }}
            required
          />
          {showErrorMessage && <Error message={userNameErrorMessage} />}
        </div>
      </div>
      <div className="my-[00px] lg:my-[10px] text-left md:w-[55%] w-[80%] m-auto">
        <label htmlFor="enterPassword">
          {" "}
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "enter_a_new_password"
              )[0].keyValue
            : "Enter Password"}
        </label>
        <div className=" relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
          <input
            id="Password"
            name="Password"
            className="bg-[#F6F7FA] w-full !outline-none"
            type={showPassword ? "text" : "password"}
            placeholder={
              getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "enter_password_txt"
                  )[0].keyValue
                : "Enter Password"
            }
            minLength={6}
            maxLength={20}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value === " ") {
                e.preventDefault();
              } else {
                handleChange(e, "setPassword");
              }
            }}
            required
          />

          <PasswordToggle
            showPassword={showPassword}
            setShowPassword={() => togglePassword("password")}
          />
        </div>
      </div>
      <div className="my-[0px] lg:my-[10px] text-left md:w-[55%] w-[80%] m-auto">
        <label htmlFor="confirmPassword" className="text-left">
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "confirm_new_password"
              )[0].keyValue
            : "Confirm Password"}
        </label>
        <div className=" relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
          <input
            id="confirmPassword"
            name="confirmPassword"
            className="bg-[#F6F7FA] w-full !outline-none"
            type={showConfirmPassword ? "text" : "password"}
            placeholder={
              getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "confirm_pass_txt"
                  )[0].keyValue
                : "Confirm password"
            }
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            minLength={6}
            maxLength={20}
            onChange={(e) => {
              if (e.target.value === " ") {
                e.preventDefault();
              } else {
                handleChange(e, "setPassword");
              }
            }}
            required
          />
          <PasswordToggle
            showPassword={showConfirmPassword}
            setShowPassword={() => togglePassword("confirmPassword")}
          />
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
