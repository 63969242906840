import { useEffect, useState } from "react";
import image_pi from "../../assets/images/image_pi.png";
import { useDispatch, useSelector } from "react-redux";
import {
  faAngleRight,
  faEnvelope,
  faPowerOff,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "./SideBarMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLmsUrlSelector } from "../../redux/slices/course/courseSlice";
import { getCourseURL } from "../../redux/slices/course/courseSlice";
import "./Sidebar.css";
import { getTranslatedTextSelector } from "../../redux/slices/translatedText/getTranslatedText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { retrieveLanguages } from "../../redux/slices/language/languageSlice";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import whatsappIcon from "../../assets/images/whatsapp.png";

const SideBar = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const { isUserProfileCompleted } = props;
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const l_id = localStorage.getItem("l_id");
  const language_id = parseInt(l_id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = useSelector(getLmsUrlSelector);
  const getTranslatedText = useSelector(getTranslatedTextSelector);
  const { hideSidebar, setHideSidebar } = props.tsidebar;

  const [languageName, setLanguageName] = useState([]);
  const [disableSidebar, setDisableSidebar] = useState();
  const location = useLocation();

  const [newMenu, setNewMenu] = useState([]);
  const userTypeID = localStorage.getItem("userTypeId");

  const courseCompleted = localStorage.getItem("courseCompleted");

  const getLanguages = async () => {
    try {
      const dispatchLanguage = await dispatch(retrieveLanguages()).unwrap();

      setLanguageName(dispatchLanguage);
      return dispatchLanguage;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    // const { userName } = JSON.parse(localStorage.getItem("user"));
    if (languageName?.length > 0) {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("id");
      const lang = languageName?.filter((item) =>
        language_id === item.id ? item : ""
      );
      const lang_name = lang[0]?.name;

      dispatch(getCourseURL({ userId: userId, token, languageId: lang_name }));
    }
  }, [languageName]);

  const mCloseSidebar = () => {
    setHideSidebar(!hideSidebar);
  };
  const getText = (element) => {
    const text = getTranslatedText?.data?.filter(
      (item) => item.pageKey === element
    );
    return text[0].keyValue;
  };

  // const isProfileCompleted = () => {
  //   if (
  //     JSON.parse(localStorage.getItem("user"))?.blockId !== null &&
  //     JSON.parse(localStorage.getItem("user"))?.dob &&
  //     JSON.parse(localStorage.getItem("user"))?.gender &&
  //     JSON.parse(localStorage.getItem("user"))?.lastName &&
  //     JSON.parse(localStorage.getItem("user"))?.stateId &&
  //     JSON.parse(localStorage.getItem("user"))?.userName &&
  //     JSON.parse(localStorage.getItem("user"))?.firstName &&
  //     JSON.parse(localStorage.getItem("user"))?.mobileNo &&
  //     JSON.parse(localStorage.getItem("user"))?.districtId &&
  //     JSON.parse(localStorage.getItem("user"))?.schoolId
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const isProfileCompleted = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const requiredFields = [
      "dob",
      "gender",
      // "lastName",
      "stateId",
      "userName",
      "firstName",
      "mobileNo",
      "districtId",
    ];

    if (!user) {
      return false;
    }

    if (user.userTypeId !== 7 || user.userTypeId !== 8) {
      requiredFields.push("schoolId");
    }

    return requiredFields.every((field) => user[field]);
  };

  useEffect(() => {
    isProfileCompleted() === true
      ? setHideSidebar(true)
      : setHideSidebar(false);
  }, [isProfileCompleted()]);

  const dataCheck = localStorage?.getItem("profileCompleted");

  useEffect(() => {
    if (dataCheck !== "undefined") {
      if (dataCheck === "false") {
        setDisableSidebar(true);
      } else {
        setDisableSidebar(false);
      }
    }
  }, [dataCheck]);

  useEffect(() => {
    if (userTypeID === "9") {
      const reviewerMenu = Menu.map((item) => {
        if (item.id === 3) {
          return { ...item, disabled: true };
        }
        return { ...item };
      }).filter((item) => item.id !== 3);

      setNewMenu(reviewerMenu);
    } else {
      setNewMenu(Menu);
    }
  }, []);

  const [selectedItem, setSelectedItem] = useState(Menu[0].path);

  const changeLink = (id, isSelected) => {
    setSelectedItem(id);
    const a = { ...Menu, isSelected: true };
    const b = Menu.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: true };
      }
      return { ...item };
    });
  };

  useEffect(() => {
    if (newMenu.length > 0) {
      const c = newMenu?.map((item) => {
        if (item.path === location.pathname) {
          return { ...item, isSelected: true };
        } else if (item.children?.length > 0) {
          const d = item.children.filter((e) => e.path === location.pathname);

          if (d.length > 0) {
            return { ...item, isSelected: true };
          }
          return { ...item, isSelected: false };
        }
        return { ...item, isSelected: false };
      });

      setNewMenu(c);
    }
  }, [location]);

  return (
    <>
      <div className={`${props.hsidebar ? "sblock" : "shidden"}`}>
        <>
          {isProfileCompleted() === true ? (
            <div className="flex ">
              <div className="bg-[#303A70] bg-gradient-to-r from-[#303A70] to-[#121A43] lg:h-screen h-screen relative sm:overflow-y-scroll">
                <div className="flex justify-center relative mt-10 md:mt-0">
                  <img src={image_pi} className="w-1/2 mt-[20px]" alt="" />
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="md:hidden absolute right-5 text-[#fff] top-[-20px] text-xl cursor-pointer"
                    onClick={mCloseSidebar}
                  />
                </div>
                <div className="px-[30px] hidden">
                  {/* {Menu.map((item, index) => (
                    // <Link
                    //   onClick={() => {
                    //     if (!isProfileCompleted()) {
                    //       setShowMessage(true);
                    //       toast.warn("Please completed the profile first");
                    //     }
                    //   }}
                    //   className="flex justify-between my-3 items-center cursor-pointer"
                    //   key={index}
                    //   to={
                    //     isProfileCompleted()
                    //       ? item.path
                    //         ? `${course?.data?.response?.platformUrl}&allowhome=1`
                    //         : "/user/dashboard"
                    //       : "/user/profile"
                    //   }
                    // >
                    // <Link
                    //   className={`flex justify-between my-3 items-center cursor-pointer ${
                    //     item.title === "Screening" && courseCompleted === "0"
                    //       ? "pointer-events-none	bg-red"
                    //       : ""
                    //   }`}
                    //   key={index}
                    //   to={
                    //     item.title === "Courses"
                    //       ? `${course?.data?.response?.platformUrl}&allowhome=1`
                    //       : item.path
                    //   }
                    // >
                    <Link
                      className={`flex justify-between my-3 items-center ${
                        item.title === "Screening" &&
                        courseCompleted === "0" &&
                        courseCompleted === null
                          ? "pointer-events-none bg-red"
                          : "cursor-pointer"
                      } `}
                      key={index}
                      to={
                        item.title === "Courses"
                          ? `${course?.data?.response?.platformUrl}&allowhome=1`
                          : item.path
                      }
                      onClick={mCloseSidebar}
                    >
                      <div
                        className={`flex py-2 hover:-translate-y-1 hover:scale-110 duration-300 `}
                      >
                        <img
                          src={item.icon}
                          alt=""
                          className="mr-[10px] w-[25px]"
                        />
                        <p className="text-left text-white transition ease-in-out delay-150  mr-10">
                          {" "}
                          {getTranslatedText?.data?.length > 0
                            ? item.title === "Courses"
                              ? getText("item_courses")
                              : item.title === "Dashboard"
                              ? getText("item_home")
                              : item.title === "Certificate"
                              ? getText("certificate")
                              : item.title === "Screening"
                              ? getText("screening")
                              : item.title === "Settings"
                              ? getText("item_settings")
                              : item.title
                            : item.title}
                        </p>
                      </div>
                      <FontAwesomeIcon icon={faAngleRight} color="white" />
                    </Link>
                  ))} */}
                </div>
                <div className="">
                  <p className="text-[#B1BAE4] text-left text-[14px] mx-[25px] hidden">
                    Menu
                  </p>
                  <ul className="[&_li]:my-4 ml-[35px] div-ul">
                    {newMenu?.map((item) => (
                      <>
                        {/* if(userTypeId === "9"){(item.disabled = true)} */}
                        <li
                          key={item.path}
                          // onClick={() =>
                          //   !disableSidebar && handleItemClick(item.path)
                          // }
                          onClick={() => changeLink(item.id, item.isSelected)}
                          // style={{
                          //   color:
                          //     selectedItem === item.path ? "#F9B239" : "black",
                          // }}
                          className={`${
                            item.isSelected || location.pathname === item.path
                              ? "text-[#F9B239] [&_div]:bg-[#F9B239] [&_div]:rounded-[100%] [&_div]:w-[40px] [&_div]:h-[40px]"
                              : "text-[#B1BAE4]"
                            //: "text-[#F9B239] [&_div]:bg-[#F9B239] [&_div]:rounded-[100%] [&_div]:w-[40px] [&_div]:h-[40px]"
                          } ${
                            item.disabled ? "hidden" : ""
                          } "flex items-center  [&_div]:w-[40px] [&_div]:h-[40px] `}
                        >
                          <Link
                            to={
                              item.title === "Courses"
                                ? `${course?.data?.response?.platformUrl}&allowhome=1`
                                : item.path
                            }
                            className={`w-full flex flex-row items-center disableSidebar
                           ${
                             disableSidebar === true
                               ? "pointer-events-none"
                               : "cursor-pointer"
                           }
                          `}
                            target={item.id === 6 ? "_blank" : ""}
                            // className={`w-full flex flex-row items-center`}
                          >
                            <div className="mr-[10px] flex">
                              <img
                                src={item.icon}
                                alt=""
                                className="m-auto fill-[#243c5a] hidden"
                              />
                              <img
                                src={
                                  item.isSelected
                                    ? item.iconWhite
                                    : item.iconGrey
                                }
                                alt={item.title}
                                className="w-5 h-5 m-auto"
                              />

                              {/* {item.icon} */}
                            </div>
                            <p className="text-left transition ease-in-out delay-150  font-medium">
                              {" "}
                              {getTranslatedText?.data?.length > 0
                                ? item.title === "Courses"
                                  ? getText("item_courses")
                                  : item.title === "Home"
                                  ? getText("item_home")
                                  : item.title === "Certificate"
                                  ? getText("certificate")
                                  : item.title === "Screening"
                                  ? getText("screening")
                                  : item.title === "Settings"
                                  ? getText("item_settings")
                                  : item.title === "Intervention"
                                  ? getText("key_intervention")
                                  : item.title === "FAQ"
                                  ? getText("key_faq")
                                  : item.title
                                : item.title}
                            </p>
                            {/* {item.title} */}
                          </Link>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
                {/* <div className="flex justify-center  bsg-[#ffffff29] h-10 items-center  mx-6 rounded-[30px] absolute bottom-[30px] w-[80%]"> */}
                <div className="flex justify-start mx-4 items-center absolute sm:relative sm:mt-[70px] bottom-[20px] left-0 right-0">
                  <div className="text-white text-left">
                    <h2 className="text-[20px] mb-2 font-medium">
                      {getTranslatedText?.data?.length > 0
                        ? getTranslatedText?.data?.filter(
                            (item) => item.pageKey === "key_contact_us"
                          )[0]?.keyValue
                          ? getTranslatedText?.data?.filter(
                              (item) => item.pageKey === "key_contact_us"
                            )[0]?.keyValue
                          : "Contact Us"
                        : "Contact Us"}
                    </h2>
                    <div className="">
                      <p className="text-[14px]">
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                        support.pi@aurosociety.org
                      </p>
                      <a
                        href="https://wa.me/919355902926"
                        target="_blank	"
                        className="text-[14px] flex mt-2"
                      >
                        {" "}
                        {/* <FontAwesomeIcon icon={faWhatsapp} size="xl" /> */}
                        <img src={whatsappIcon} className="w-[20px]" />
                        +91 9355902926
                        {/* Whatsapp */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <> </>
          )}
        </>

        <ToastContainer />
      </div>
    </>
  );
};

export default SideBar;
