import React from "react";
import "./card.css";
import imgURL from "../../assets/images/image_pi.png";
import imgSbiURL from "../../assets/images/sbi-logo.png";


const Card = (props) => {
  const { color, height } = props;
  return (
    <>
      {/* <div className={`h-${height} bg-[${color}] rounded-md`} > */}
      <div className="hs-[28vh] h-full mds:h-[45vh] lgs:h-[55vh] xl:min-h-[55vh] bg-[#101942] rounded-md flex flex-col justify-center ">
        <img
          src={imgURL}
          className="w-[70%] md:w-[100%] m-auto h-full object-contain"
        />
        <img
          src={imgSbiURL}
          className="custom-sbi-image-class"
        />
      </div>
    </>
  );
};

export default Card;
