import { http, apiEndPoints } from "../../utils/http";

const headers = {
  "Content-type": "multipart/form-data",
};

const getGender = () => {
  return http.get(apiEndPoints.GET_GENDER);
};

const getGrade = (languageId) => {
  return http.get(`${apiEndPoints.GET_GRADE}/${languageId}`);
};

const getStates = () => {
  return http.get(apiEndPoints.GET_STATES);
};

const getDistrictByStateId = (stateId) => {
  return http.get(`${apiEndPoints.GET_DISTRICT_BY_STATE_ID}/${stateId}`);
};

const getBlockByDistrictId = (DistrictId) => {
  return http.get(`${apiEndPoints.GET_BLOCK}/${DistrictId}`);
};

const getSchoolById = (stateId, districtId) => {
  return http.get(
    `${apiEndPoints.GET_SCHOOL_BY_DISTRICT_ID}/${stateId}/${districtId}`
  );
};

const updateUserProfile = (data) => {
  return http.post(apiEndPoints.UPDATE_PROFILE, data, headers);
};
const updateUserProfileNoImage = (data) => {
  return http.post(apiEndPoints.UPDATE_PROFILE_NO_IMAGE, data, headers);
};
const getSchoolByUdise = (data) => {
  return http.post(apiEndPoints.GET_SCHOOL_BY_UDISE, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const profile = {
  getGender,
  getGrade,
  getStates,
  getDistrictByStateId,
  getSchoolById,
  updateUserProfile,
  getBlockByDistrictId,
  getSchoolByUdise,
  updateUserProfileNoImage,
};

export default profile;
