import ReactIcons from "../icons";
const PasswordToggle = ({ showPassword, setShowPassword }) => {
  return (
    <div>
      {showPassword ? (
        <ReactIcons.AiOutlineEyeInvisible
          className="absolute right-[2rem] top-3 ml-3 cursor-pointer	"
          onClick={() => {
            setShowPassword();
          }}
        />
      ) : (
        <ReactIcons.AiOutlineEye
          className="absolute right-[2rem] top-3 ml-3 cursor-pointer	"
          onClick={() => {
            setShowPassword();
          }}
        />
      )}
    </div>
  );
};

export default PasswordToggle;
