import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CourseListShimmer = () => {
  return (
    <div className="border-b-[1px] bg-[#fff] flex flex-row justify-between items-center px-[20px] py-[10px]">
      <div className="w-[40px] h-[40px]">
        <Skeleton width={40} height={40} />
      </div>
      <p className="pl-2 w-full">
        <Skeleton count={2} />
      </p>
    </div>
  );
};

const ModuleListShimmer = () => {
  return (
    <>
      <div className="border-b-[1px]">
        <div className="px-4  mt-4 flex flex-row justify-between">
          <div className="text-left w-[80%]">
            <p className="text-start">
              <Skeleton count={2} />
            </p>
          </div>

          <div className="w-[40px] h-[40px]">
            <Skeleton width={40} height={40} />
          </div>
        </div>
        <>
          <div className="flex justify-around mt-2">
            <div className="w-[42%]">
              <Skeleton />
            </div>
            <div className="w-[42%]">
              <Skeleton />
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export { CourseListShimmer, ModuleListShimmer };
