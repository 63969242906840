import { http, apiEndPoints } from "../../utils/http";
import { headers } from "../../utils/http/headers";
import axios from "axios";
import { baseURL } from "../../utils/http/http-common";

const getUserType = () => {
  const languageId = localStorage.getItem('l_id');
  
  return http.get(`${apiEndPoints.USER_TYPE}/${languageId}`, headers);
};

const getLanguages = () => {
  return http.get(apiEndPoints.GET_LANGUAGE, headers);
};

const validateUser = (userName, userTypeId) => {
  const body = {
    UserName: userName,
    UserTypeId: userTypeId,
  };

  // Encode each key-value pair in the body object
  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  // Construct the URL with the encoded body
  return http.get(`${apiEndPoints.VALIDATE_USER}?${encodedBody}`);
};

const getToken = (data) => {
  return http.post(apiEndPoints.GET_TOKEN, data);
};

const sendOtp = (mobileNo) => {
  const body = {
    MobileNo: mobileNo,
  };

  // Encode each key-value pair in the body object
  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  // Construct the URL with the encoded body
  return http.post(`${apiEndPoints.SEND_OTP}?${encodedBody}`);

};

const verifyOtp = (mobileNo, otp) => {
  const body = {
    MobileNo: mobileNo,
    OTP: otp,
  };

  // Encode each key-value pair in the body object
  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  // Construct the URL with the encoded body
  return http.post(`${apiEndPoints.VERIFY_OTP}?${encodedBody}`);
};

const login = (data) => {
  return http.post(apiEndPoints.LOGIN, data);
};

const loginWithOtp = (data) => {
  return http.post(apiEndPoints.LOGIN_WITH_OTP, data);
};

const register = (userName, userTypeId, password, MobileNo) => {
  const body = {
    UserName: userName,
    UserTypeId: userTypeId,
    Password: password,
    MobileNo: MobileNo,
  };

  // Encode each key-value pair in the body object
  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  // Construct the URL with the encoded body
  return http.post(`${apiEndPoints.REGISTER}?${encodedBody}`);
};

const forgotPass = (Password) => {
  // Convert password to encodeURIComponent format
  const encodedPassword = encodeURIComponent(Password);

  var data = localStorage.getItem("id");
  
  var config = {
    method: "post",
    url: `${baseURL}/Login/SetPassword?Password=${encodedPassword}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const deactivateUser = (userId) => {
  const body = {
    userId: userId,
  };

  // Encode each key-value pair in the body object
  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  return http.post(`${apiEndPoints.DACTIVATE_USER}?${encodedBody}`, null, {
    headers: headers,
  });
};
export const activateUser = (userId) => {
  const body = {
    userId: userId,
  };

  // Encode each key-value pair in the body object
  const encodedBody = Object.entries(body)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return http.post(`${apiEndPoints.ACTIVATE_USER}?${encodedBody}`);
};
const auth = {
  getUserType,
  getLanguages,
  validateUser,
  sendOtp,
  verifyOtp,
  login,
  loginWithOtp,
  register,
  forgotPass,
  getToken,
};

export default auth;
