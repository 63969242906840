import Cookies from 'js-cookie';

export const BANNER_SHOW = 'banner_show';

const clearBanner = () => Cookies.remove(BANNER_SHOW);

const clearAllCookies = () => {
    clearBanner();
    
};

const setBanner = (contentValue) => {
    const millisecondsInOneDay = 24 * 60 * 60 * 1000;

    if (!contentValue) {
        return;
    }

    Cookies.set(BANNER_SHOW, contentValue);

    setTimeout(clearAllCookies, millisecondsInOneDay);
};

const getBanner = () => Cookies.get(BANNER_SHOW);

export {
    setBanner,
    getBanner,
    clearBanner
};
