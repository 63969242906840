import React from "react";

function Modal({ children, visible, onClose }) {
  if (!visible) return null;
  
  const handleOnBackDropClick = (e) => {
    if (e.target.id === "backdrop") onClose && onClose();
  };

  return (
    <>
      <div
        className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50"
        id="backdrop"
        onClick={handleOnBackDropClick}
      >
        {children}
      </div>
    </>
  );
}

export default Modal;
